<template>
  <div class="container__ask_code2">
    <!-- Remove the commented-out div -->
    <section>
      <div class="main-corporative-container">
        <div class="cp-header-logo">
          <img class="bilheteria-logo" src="/assets/images/logocorporativobilheteria.png" alt="Bilheteria.com" />
        </div>
        <div class="cp-header-title">
          <h1 class="txts-askcode-bilheteria">BEM-VINDO AO PROGRAMA CORPORATIVO DE ENTRETENIMENTOS!</h1>
        </div>
        <div class="cp-description form-container">
          <p class='cp-title'>Sua empresa não está cadastrada, mas não se preocupe. Preencha o cadastro abaixo. Será enviado um e-mail automático para o e-mail do RH que você indicou com todas as informações do programa. Um consultor receberá uma cópia para entrar em contato com eles para verificar a viabilidade de credenciar a empresa para que você, seus colegas e familiares tenham acesso aos benefícios do programa.</p>
        </div>
        <div class="form-container" :key='formKey'>
          <div class="form-section">
            <p class='cp-label'>Sobre sua empresa:</p>
            <div class="form-fields-container">
              <input v-model="form.emp_cnpjcpf" :class='{ errorinputuser: hasError && !form.emp_cnpjcpf }' type='text'
                placeholder="CNPJ" v-mask="'##.###.###/####-##'"  class="form-control form-item" />
              <v-select class='input_type_select form-item' placeholder='Tipo' :options="types" :clearable='false'
                :reduce="type => type.tpc_id" label="tpc_nome" v-model="form.emp_idtipocadastro"
                :class='{ errorinputuser: hasError && form.emp_idtipocadastro === "" }' />
              <v-select class='input_type_select form-item' placeholder='Fornece Vale Cultura?'
                :options="[{ label: 'Sim', value: 1 }, { label: 'Não', value: 0 }]" :clearable='false'
                :reduce="type => type.value" label="label" v-model="form.emp_valecultura"
                :class='{ errorinputuser: hasError && form.emp_valecultura === "" }' />

              <input v-model="form.emp_razaosocial" :class='{ errorinputuser: hasError && !form.emp_razaosocial }'
                type='text' placeholder="Razão Social" class="form-control form-item" />
              <input v-model="form.emp_nomefantasia" :class='{ errorinputuser: hasError && !form.emp_nomefantasia }'
                type='text' placeholder="Nome fantasia" class="form-control form-item" />
              <input v-model="form.emp_qntcolaboradores"
                :class='{ errorinputuser: hasError && !form.emp_qntcolaboradores }' type='number'
                placeholder="Quant. de funcionários" class="form-control form-item" />
            </div>
          </div>
          <div class="form-section">
            <p class='cp-label'>Endereço</p>
            <div class="form-fields-container">
              <input :class='{ errorinputuser: hasError && !form.end_cep }' v-model="form.end_cep" type='text'
                placeholder="CEP"  v-mask='"#####-###"' class="form-control form-item" />
              <input :disabled="true" :class='{ errorinputuser: hasError && !form.end_logradouro }'
                v-model="form.end_logradouro" type='text' placeholder="Logradouro" class="form-control form-item" />
              <input :disabled="!form.end_logradouro" :class='{ errorinputuser: hasError && !form.end_numero }'
                v-model="form.end_numero" type='text' placeholder="Número" class="form-control form-item" />

              <input :disabled="true" :class='{ errorinputuser: hasError && !form.end_bairro }'
                v-model="form.end_bairro" type='text' placeholder="Bairro" class="form-control form-item" />
              <input :disabled="true" :class='{ errorinputuser: hasError && !form.end_cidade }'
                v-model="form.end_cidade" type='text' placeholder="Cidade" class="form-control form-item" />
              <input :disabled="true" :class='{ errorinputuser: hasError && !form.end_uf }' v-model="form.end_uf"
                type='text' placeholder="UF" class="form-control form-item" />
            </div>
          </div>
          <div class="form-section">
            <p class='cp-label'>Contato do responsável: (RH/Administração/Gerência)</p>
            <div class="form-fields-container">
              <!-- <input :class='{ errorinputuser: hasError && !form.emp_admincpf }' v-model="form.emp_admincpf" type='text'
                placeholder="CPF do administrador" class="form-control form-item" /> -->
              <input :class='{ errorinputuser: hasError && !form.emp_adminnome }' v-model="form.emp_adminnome"
                type='text' placeholder="Nome do administrador" class="form-control form-item" />
              <input :class='{ errorinputuser: hasError && !form.emp_admincargo }' v-model="form.emp_admincargo"
                type='text' placeholder="Cargo" class="form-control form-item" />

              <input :class='{ errorinputuser: hasError && !form.emp_admintelefone }' v-model="form.emp_admintelefone"
                type='text' v-mask='"## ####-####"' placeholder="Telefone" class="form-control form-item" />
              <input :class='{ errorinputuser: hasError && !form.emp_admincelular }' v-model="form.emp_admincelular"
                type='text' v-mask='"## #####-####"' placeholder="Celular" class="form-control form-item" />
              <input :class='{ errorinputuser: hasError && !form.emp_adminemail }' v-model="form.emp_adminemail"
                type='text' placeholder="E-mail corporativo" class="form-control form-item" />
            </div>
          </div>
          <div class="form-section">
            <p class='cp-label'>Responsável pelo cadastro (preencha com seus dados)</p>
              <div class='form-contact'>
                <input :class='{ errorinputuser: hasError && !form.rsp_nome }' v-model="form.rsp_nome" type='text'
                  placeholder="Nome" class="form-control form-item" />
                <input
                  :class='{ errorinputuser: hasError && (!form.rsp_emailalternativo || !form.rsp_emailalternativo.match("@")) }'
                  v-model="form.rsp_emailalternativo" type='email' placeholder="E-mail corporativo" class="form-control form-item" />
                <input :class='{ errorinputuser: hasError && !form.rsp_telefone }' v-model="form.rsp_telefone"
                  type='tel' v-mask='"## ####-####"' placeholder="Telefone" class="form-control form-item" />
                <input :class='{ errorinputuser: hasError && !form.rsp_celular }' v-model="form.rsp_celular" type='tel'
                v-mask='"## #####-####"' placeholder="Celular" class="form-control form-item" />
                <input :class='{ errorinputuser: hasError && !form.rsp_departamento }' v-model="form.rsp_departamento"
                  type='text' placeholder="Departamento" class="form-control form-item" />
                <input :class='{ errorinputuser: hasError && !form.rsp_cargo }' v-model="form.rsp_cargo" type='text'
                  placeholder="Cargo" class="form-control form-item" />
              </div>
          </div>
          <div class="btn-contact">
              <b-button style='background: #FF3C66;'
                @click="$router.go(-2)">VOLTAR</b-button>
            </div>
          <div class="btn-send">
            <b-button @click="requestBws">ENVIE E AGUARDE UM DOS NOSSOS
              REPRESENTANTES CONTATA-LO</b-button>
          </div>
        </div>
        <img class="cp-horizontal-desktop-background" src="/assets/images/novo_corporativo/horizontal_wave.svg" />
        <div class="cp-footer"></div>
      </div>
    </section>
    <LottieLoader :loading="processing"></LottieLoader>
  </div>
</template>

<script>
import Vue from "vue";
import VueMask from "v-mask";
import VModal from "vue-js-modal";
import config from "@/config";
import { func } from "@/functions";
import { corporateService } from "@/components/common/services/corporate";
import vSelect from "vue-select"; // Import Vue Select for dropdowns
import axios from "axios"; // Import Axios for API requests
import LottieLoader from "@/components/loaders/LottieLoader";
import { externalService } from "@/components/common/services/external";
Vue.use(VueMask);
Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
});

export default {
  mixins: [func],
  name: "BwsCorporate",
  components: { vSelect, LottieLoader },
  directives: {},
  computed: {},
  head: {
    title: function () {
      return {
        inner: "",
        separator: " ",
        complement: "Programa Corporativo Bilheteria.com",
      };
    },
  },
  async mounted() {
    if (this.$route.params.cnpj) {
      this.watchEnabled = false;
      this.form.emp_cnpjcpf = this.sanitizeValue(this.$route.params.cnpj);
      this.form.emp_nomefantasia = this.sanitizeValue(this.$route.params.fantasia);
      this.form.emp_razaosocial = this.sanitizeValue(this.$route.params.nome);
      this.form.end_cep = this.sanitizeValue(this.$route.params.cep)
      this.form.end_logradouro = this.sanitizeValue(this.$route.params.logradouro);
      this.form.end_numero = this.sanitizeValue(this.$route.params.numero);
      this.form.end_bairro = this.sanitizeValue(this.$route.params.bairro);
      this.form.end_cidade = this.sanitizeValue(this.$route.params.municipio);
      this.form.end_uf = this.sanitizeValue(this.$route.params.uf);
      await new Promise(resolve => setTimeout(resolve, 300));
      this.watchEnabled = true;
    }
    this.debouncedValidateCep = this.debouncedValidate(this.getZipCode, 1500);
    this.debouncedValidateEmail = this.debouncedValidate(this.validateEmail, 2500);
    this.debouncedValidateCnpj = this.debouncedValidate(this.getCnpjInfo, 2500);
    this.populateType();
  },
  methods: {
    sanitizeValue(value) {
      return value ? value.trim().replaceAll(".", "").replaceAll("/", "").replaceAll("-", "").replaceAll("*", "") : '';
    },
    async getCnpjInfo() {
      if (this.processing) return false;
      this.processing = true;
      
      const res = await corporateService.getCnpjInfo(this.sanitizeValue(this.form.emp_cnpjcpf));
      
      if (res && ['1', '2'].includes(res.code)) {
        this.$root.$emit('message-alert', { title: "CNPJ não encontrado" });
        this.watchEnabled = false;
        this.form.emp_cnpjcpf = '';
        await new Promise(resolve => setTimeout(resolve, 300));
        this.processing = false;
        this.watchEnabled = true;
        return false;
      }
      
      this.processing = false;
      if ('7' === res.code) { // provavelmente eles fizeram um crawler na receita, quando a receita cai eles tb ficam off 
        return false;
      }
  
      this.form.emp_nomefantasia = this.sanitizeValue(res.fantasia);
      this.form.emp_razaosocial = this.sanitizeValue(res.nome);
      this.form.end_cep = this.sanitizeValue(res.cep);
      this.form.end_logradouro = this.sanitizeValue(res.logradouro);
      this.form.end_numero = this.sanitizeValue(res.numero);
      this.form.end_bairro = this.sanitizeValue(res.bairro);
      this.form.end_cidade = this.sanitizeValue(res.municipio);
      this.form.end_uf = this.sanitizeValue(res.uf);
      this.formKey++;
    },
    async requestBws() {
      if (this.processing) return false;
      this.processing = true;
      this.hasError = false;
      for (let key in this.form) {
        if (key.startsWith('rsp_') && !this.shouldValidateExtraInfo) continue;
        if (key === 'emp_valecultura' && this.form[key] === 0) continue; // bypass zero value
        if (!this.form[key]) {
          this.hasError = true;
        }
      }
      if (this.hasError) {
        this.processing = false;
        this.$root.$emit('message-alert', { title: "Preencha todos os campos" });
        return;
      }
      try {
        let response = await corporateService.cadastrarEmpresa({ ...this.form, end_cep: this.sanitizeValue(this.form.end_cep) });
        if (response && response.data && response.data.status) {
          this.$root.$emit('message-alert', { title: "O cadastro foi efetuado com sucesso. O RH da sua empresa será contatado em breve." });
          await corporateService.notifyTriggers(this.form);
          return this.$router.push('askCode');
        } else {
          this.$root.$emit('message-alert', { title: response.data.info });
        }
      } catch (error) {
        this.$root.$emit('message-alert', { title: error.message });
      }
      this.processing = false;
    },
    async populateType() {
      const response = await axios.get('https://bws.bilheteria.com/api/getTipoDeCadastro')
      if (response && response.data) {
        this.types = response.data.data || []
      }
    },
    async getZipCode() {
      if (this.processing) return;
      if (!this.form.end_cep) return;
      let self = this;
      try {
        this.processing = true;
        let response = await externalService.validateCep(this.sanitizeValue(this.form.end_cep))
        if (response && response.body) response = response.body;
        self.form.end_uf = response.uf;
        self.form.end_bairro = response.bairro;
        self.form.end_logradouro = response.logradouro;
        self.form.end_cidade = response.localidade;
      } catch (error) {
        this.$root.$emit('message-alert', { title: "CEP inválido" });
      }
      this.processing = false;
    },
    async validateEmail() {
      if (this.processing) return false;
      const email = this.form[this.keyToValidate];
      if (!email) return;
      this.processing = true;
      try {
        const response = await fetch(`https://api.bilheteria.com.br/v1/zerobounce/validar_email.php?email=${email}`);
        const data = await response.json();

        // Update the result based on the API response
        const isValid = data.status === 'valid';
        if (!isValid) {
          this.$root.$emit('message-alert', { message: data.status_message });
          this.form[this.keyToValidate] = '';
        }
      } catch (error) {
        this.$root.$emit('message-alert', { message: error.message });
      }
      this.processing = false;
    },
    debouncedValidate: function debounce(func, delay) {
      let timer;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this);
        }, delay);
      };
    },
  },
  watch: {
    'form.end_cep': async function () {
      if (!this.watchEnabled) return;
      this.debouncedValidateCep()
    },
    'form.emp_adminemail': function () {
      if (!this.watchEnabled) return;
      this.keyToValidate = 'emp_adminemail';
      this.debouncedValidateEmail();
    },
    'form.rsp_emailalternativo': function () {
      if (!this.watchEnabled) return;
      this.keyToValidate = 'rsp_emailalternativo';
      this.debouncedValidateEmail();
    },
    'form.emp_cnpjcpf': function () {
      if (!this.watchEnabled) return;
      this.keyToValidate = 'emp_cnpjcpf';
      this.debouncedValidateCnpj();
    },
  },
  data() {
    return {
      processing: false,
      formKey: 0,
      watchEnabled: true,
      types: [],
      shouldValidateExtraInfo: true,
      hasError: false,
      keyToValidate: '',
      form: {
        acao: "salvarempresapelosite", // salvarempresapelosite
        end_origem: "SISTEMA", // SISTEMA
        emp_cnpjcpf: "", // 26.923.902/0001-93
        emp_idtipocadastro: "", // 1
        emp_valecultura: "", // 1
        emp_razaosocial: "", // teste 3423432432
        emp_nomefantasia: "", // teste
        emp_qntcolaboradores: "", // 3342
        end_cep: "", // 0000000
        end_logradouro: "", // xxxxx
        end_numero: "", // xxxxxx
        end_bairro: "", // xxxxxx
        end_cidade: "", // São Paulo
        end_uf: "", // SP
        emp_adminnome: "", // teste 432423
        emp_admincargo: "", // teste
        emp_admintelefone: "", // xxxxxxxxxx
        emp_admincelular: "", // xxxxxxxxxxxx
        emp_adminemail: "", // xxxx
        rsp_nome: "", // xxxxxx
        rsp_emailalternativo: "", //xxxxxxxxx
        rsp_telefone: "", // (43) 2343-2432
        rsp_celular: "", // (43) 2 4322-3432
        rsp_departamento: "", // 432432432
        rsp_cargo: "", // 432432432432
      },
    };
  }
};
</script>

<style lang="scss" scoped>
.black-background {
  background-color: black !important;
}

.cp-horizontal-desktop-background {
  width: 100%;
}

.main-corporative-container {
  position: relative;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: IBM Plex Sans;

  .cp-title,
  .cp-label {
    font-weight: 600;
    font-size: 1rem;
    color: white;
  }

  .bilheteria-logo {
    width: 100%;
  }

  .txts-askcode-bilheteria {
    font-size: 1.3rem;
    width: 80vw;
    font-weight: 800;
    color: #FF9701;
    text-align: center;
    height: 5vw;
  }

  .cp-header-title {
    display: flex;
    justify-content: center;
  }

  .form-container {
    width: 83%;
    padding: 20px;

    .form-section {
      display: flex;
      flex-flow: column;
      margin: 20px 0px;

      .form-fields-container {
        display: grid;
        gap: 23px;
        grid-template-columns: repeat(3, 1fr);

        .form-item {
          flex: 1;
        }

        .form-item:disabled {
          background-color: #bdbdbd;
        }
      }
    }
  }

  .cp-description {
    text-align: center;
    margin-top: 2rem;
  }


  .btn-contact {
    gap: 7px;
    display: flex;
    flex-wrap: wrap;

    button {
      border-radius: 15px;
      background: #ef892e;
      margin-bottom: 20px;
      border: none;
    }
  }

  .btn-send {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100px;

    button {
      background: #FF5946;
      white-space: wrap;
      margin-bottom: 20px;
      border-radius: 15px;
    }
  }

  .form-contact {
    display: grid;
    gap: 23px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@keyframes errorinputuser {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-10px);
  }

  40% {
    transform: translateX(10px);
  }

  60% {
    transform: translateX(-10px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

/* Apply the shake animation to the .shake class */
.errorinputuser {
  border: 2px solid #ef892e !important;
  animation: errorinputuser 0.5s;
  border-radius: 6px;
}

@media screen and (max-width: 500px) {
  .cp-horizontal-desktop-background {
    display: none;
  }

  .main-corporative-container {

    .cp-header-title {
      height: 100px;
    }

    .cp-title {
      padding: 1rem;
    }

    .form-fields-container {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .form-contact {
      grid-template-columns: repeat(1, 1fr);
    }

    .btn-contact {
      button {
        width: 100%;
      }
    }

    .cp-footer {
      width: 100%;
      height: 300px;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('/assets/images/novo_corporativo/wave_mobile.png');
    }
  }

}
</style>