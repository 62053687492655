<template>
  <div>
    <p>EVENT</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
</style>