<script>
/**
 * basicamente retorna uma tag style que podemos inserir codigo js <style></style>
 */
export default {
  render: function (createElement) {
    return createElement('style', this.$slots.default)
  }
}
</script>
