<template>
  <div class="col-12 col-md-12 col-sm-12 container-request-corporate" style="justify-content: center" :key="updateForm">
    <div class="leftcontact col-12 col-md-12 col-sm-12" v-for="fieldGroup in formFields" :key="fieldGroup[0].value"
      style="display: flex; justify-content: center; margin: 0 auto;align-items: center;">
      <template>
        <template v-for="field in fieldGroup">
          <input :key="field.value" type="text" v-model="form[field.value]" :placeholder="field.text"
            :required="field.required" v-bind:class="{
              errorinputuser: !field.pass,
              'form-control': true,
            }" v-if="!field.isSelect" />
          <v-select class='input_type_select' style='min-width: 35% !important;gap: 3px;' :clearable='false' :key="field.value"
            :searchable='true' v-model="form[field.value]" :placeholder="field.text" :required="field.required"
            v-if="field.isSelect" :options="field.options" v-bind:class="{
              errorinputuser: !field.pass,
            }"></v-select>
        </template>
      </template>
    </div>
    <div class='cp-buttons'>
      <div class='cp-button' style='background: #FF3C66;' @click="$router.push('VerifyAccess')">
        <span>VOLTAR</span>
      </div>
      <div class='cp-button' @click="sendRequest">
        <span>ENVIAR</span>
      </div>
    </div>
    <LottieLoader :loading="processing" squareColor="#fdc80a"></LottieLoader>
  </div>
</template>


<script>
import Vue from "vue";
import VModal from "vue-js-modal";
import config from "@/config";
import { func } from "@/functions";
import { externalService } from "@/components/common/services/external";
import { corporateService } from "@/components/common/services/corporate";
import axios from "axios"; // Import Axios for API requests
import vSelect from "vue-select"; // Import Vue Select for dropdowns
import LottieLoader from "@/components/loaders/LottieLoader";

Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
});

Vue.component("v-select", vSelect); // Register Vue Select globally

export default {
  mixins: [func],
  name: "FormRequestCorporate",
  components: {LottieLoader},
  directives: {},
  computed: {},
  head: {
    title: function () {
      return {
        inner: "",
        separator: " ",
        complement: "Programa Corporativo Bilheteria.com",
      };
    },
  },
  methods: {
    async sendRequest() {
      await this.validateEmail();
      // should validate dynamic required fields not empty
      const requiredFields = this.formFields.filter((field) => {
        return field.some((f) => f.required);
      });
      // typeof object or string
      let hasWrongFields = false;
      requiredFields.forEach((fields) => {
        fields.forEach((field) => {
          if (!['email'].includes(field.value)) {
            field.pass = Boolean(this.form[field.value]);
            if (typeof this.form[field.value] === 'object') {
              field.pass = Boolean(this.form[field.value].value);
            }
          }
          if (!field.pass) hasWrongFields = true;
        });
      });
      if (hasWrongFields) {
        return this.$root.$emit('message-alert', {title: "Preencha todos os campos obrigatórios"});
      }
      if (this.processing) return false;
      this.processing = true;
      this.$wait.start("inprocess");
      this.showWaitAboveAll();
      const estado = typeof this.form.estado === 'object' ? this.form.estado.label : this.form.estado;
      const cidade = typeof this.form.cidade === 'object' ? this.form.cidade.label : this.form.cidade;

      const objt = {
        ...this.form,
        estado,
        cidade,
      };

      const res = await corporateService.requestRegister(objt);
      if (res && res.success) {
        this.$root.$emit('message-alert', { title: "Solicitação enviada com sucesso" });
        this.watchEnabled = false; // disable watch
        this.form = {};
        await new Promise((resolve) => setTimeout(resolve, 200)); // wait for the form to reset
        this.watchEnabled = true; // enable watch
      } else {
        this.$root.$emit('message-alert', {title: "Error inesperado" });
      }
      this.hideWaitAboveAll();
      this.$wait.end("inprocess");
      this.processing = false;
    },
    async getStates() {
      let url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados`;
      try {
        const { data } = await axios.get(url);
        return data.map((state) => ({
          label: state.nome,
          value: state.id,
        }));
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getMunicipalities(value) {
      let url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`;
      try {
        const { data } = await axios.get(url);
        return data.map((municipality) => ({
          label: municipality.nome,
          value: municipality.id,
        }));
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getZipCode() {
      this.processing = true;
      let self = this;
      try {
        let response = await externalService.validateCep(this.form.cep)
        if (response && response.body) response = response.body;
        self.form.estado = response.uf;
        self.form.bairro = response.bairro;
        self.form.rua = response.logradouro;
        self.form.cidade = response.localidade;

        self.formFields[5][0].pass = true; // bairro
        self.formFields[5][1].pass = true; // rua
        self.formFields[4][0].pass = true; // estado
        self.formFields[4][1].pass = true; // cidade
        self.formFields[4][2].pass = true; // cep
        self.updateForm++;
      } catch (error) {
        // this.formFields[4][2].pass = false; // cep
      }
      this.processing = false;
    },
    async validateEmail() {
      if (this.form.email === '') {
        this.formFields[1][0].pass = false;
        return;
      }
      this.processing = true;
      this.formFields[1][0].pass = true;
      try {
        const response = await fetch(`https://api.bilheteria.com.br/v1/zerobounce/validar_email.php?email=${this.form.email}`);
        const data = await response.json();

        // Update the result based on the API response
        const isValid = data.status === 'valid';
        this.formFields[1][0].pass = isValid;
        if (!isValid) {
          this.$root.$emit('message-alert', {message: data.status_message });
        }
      } catch (error) {
        console.error('Error validating email:', error);
        this.formFields[1][0].pass = false;
      }
      this.processing = false;
    },
    debouncedValidate: function debounce(func, delay) {
      let timer;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this);
        }, delay);
      };
    }
  },
  watch: {
    'form.estado': async function (newVal, oldVal) {
      if (!this.watchEnabled) return; 
      const isToFindMunicipalities = !oldVal || (newVal && oldVal && newVal.value && newVal.value !== oldVal.value);
      if (isToFindMunicipalities && !this.form.cep) {
        this.processing = true;
        this.$wait.start("inprocess");
        this.showWaitAboveAll();
        const municipalities = await this.getMunicipalities(newVal.value);
        this.formFields[4][1].options = municipalities;
        this.form.cidade = undefined;
        this.hideWaitAboveAll();
        this.$wait.end("inprocess");
        this.processing = false;
      }
    },
    'form.email': async function () {
      if (!this.watchEnabled) return;
      this.debouncedValidateEmail()
    },
    'form.cep': async function () {
      if (!this.watchEnabled) return;
      this.debouncedValidateCep()
    },
  },
  data() {
    return {
      updateForm: 0,
      watchEnabled: true,
      formFields: [
        [
          {
            text: "Nome *",
            value: "nome",
            type: "text",
            required: true,
            pass: true
          },
        ],
        [
          {
            text: "Email *",
            value: "email",
            type: "text",
            required: true,
            pass: true,
            debounced: true
          },
        ],
        [
          {
            text: "Nome da Empresa",
            value: "empresa",
            type: "text",
            required: true,
            pass: true
          },
          {
            text: "CPF ou CNPJ *",
            value: "cpf_cnpj",
            type: "text",
            required: true,
            pass: true
          },
        ],
        [
          {
            text: "DDD *",
            value: "ddd",
            type: "text",
            required: true,
            pass: true
          },
          {
            text: "Celular *",
            value: "celular",
            type: "text",
            required: true,
            pass: true
          },
        ],
        [
          {
            text: "UF",
            value: "estado",
            type: "select",
            isSelect: true,
            options: [],
            required: true,
            pass: true
          },
          {
            text: "Cidade",
            value: "cidade",
            type: "select",
            isSelect: true,
            options: [],
            required: true,
            pass: true
          },
          {
            text: "Cep",
            value: "cep",
            type: "text",
            required: true,
            pass: true,
            debounced: true
          },
        ],
        [
          {
            text: "Bairro",
            value: "bairro",
            type: "text",
            required: true,
            pass: true
          },
          {
            text: "Rua",
            value: "rua",
            type: "text",
            required: true,
            pass: true
          },
          {
            text: "Número",
            value: "numero",
            type: "text",
            required: true,
            pass: true
          },
        ],
        [
          {
            text: "Complemento",
            value: "complemento",
            type: "text",
            required: true,
            pass: true
          },
        ],
      ],
      processing: false,
      loaded: false,
      showwatch: "login",
      showResetCodeMessage: false,
      form: {
        searchTerm: "",
      },
    };
  },
  async mounted() {
    this.debouncedValidateEmail = this.debouncedValidate(this.validateEmail, 2000);
    this.debouncedValidateCep = this.debouncedValidate(this.getZipCode, 1500);
    this.processing = true;
    this.$modal.hide("login");
    const states = await this.getStates();
    this.processing = false;
    this.formFields[4][0].options = states;
  },
};
</script>

<style scoped lang='scss'>
.cp-buttons {
  width: 100%;
  display: flex;
  gap: 22px;

  .cp-button {
    width: 60%;
    background: #EF892E;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 39px;
    border-radius: 12px;
    cursor: pointer;

    span {
      font-weight: bold;
      color: white;
    }
  }
}
@keyframes errorinputuser {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-10px);
  }

  40% {
    transform: translateX(10px);
  }

  60% {
    transform: translateX(-10px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

/* Apply the shake animation to the .shake class */
.errorinputuser {
  border: 2px solid #ef892e !important;
  animation: errorinputuser 0.5s;
  border-radius: 14px;
}

.form-control {
  margin: 2px;
  border-radius: 12px;
}

@media screen and (max-width: 500px) {

  .div-btn-enviar .btn {
    width: 100% !important;
  }
}
</style>
<style lang='scss'>
.container-request-corporate {
  padding: 0 !important;
  div {
  padding: 0 !important;
  margin: 2px;
  }
  .vs__open-indicator {
    transform: scale(0.7);
  }

  .input_type_select .vs__dropdown-toggle {
    padding: 0px;
    height: 40px;
    font-size: 14px;
    order: none;
    margin: 0px;
    border-radius: 12px;
  }
}
</style>