<template>
  <div class="a">
    <div>
      <!-- <section
        v-if="siteName == 'corporativo.bilheteria.com.br'"
        class="to-block to-viewport bg-dark bg__main"
        style
        data-block-type="call_to_action"
        data-id="2"
      >
        <div
          v-if="siteName == 'corporativo.bilheteria.com.br'"
          class="
            container
            justify-content-center
            align-items-center
            d-flex
            pt-5
            pb-0
            mb-0
            h-25
          "
        >
          <div class="col-10 justify-content-center text-center">
            <div class="d-none d-sm-block" style>
              <h2>Deseja encontrar outro evento ?</h2>
            </div>
            <div class="d-none d-sm-block input-group mt-4 mb-0 p-1 w-100">
              <app-search></app-search>
            </div>
          </div>
        </div>
      </section> -->
      <!-- Propaganda principal corporativo -->
      <CorporateDefaultBanner v-if="siteName === 'corporativo.bilheteria.com.br' && !isMobile"/>
      <!-- PROPAGANDA -->
      <div class="container-fluid container__select" v-if="!isMobile && discoveryBanner.length > 0 && siteName == 'corporativo.bilheteria.com.br'">
        <div class="container p-0">
          <div class="row">
            <div class="col-12 col-xl-12 text-left">
              <div class="p-3">
                <div class="container__arrows" style="margin-top: auto">
                  <div class="swiper-button-prev swiper-button-prev-banner" slot="button-prev"></div>
                  <div class="swiper-button-next swiper-button-next-banner" slot="button-next"></div>
                </div>
                <swiper :options="swiperOptionBanner">
                  <swiper-slide v-for="(item, index) in discoveryBanner" :key="index">
                    <img class="img-fluid rounded-0 discovery" v-bind:src="(item.imageURI)" :alt="item.title" @click="goto('discovery', item.link)">
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- PROPAGANDA -->
      <!-- FILTRO CATEGORIAS -->
       <section  v-if="categoriesList && categoriesList.length > 0 && siteName === 'corporativo.bilheteria.com.br'"
          class="to-block fdb_result fix-position-corporativo"
          style="padding: 0 !important; position: sticky; top: 30px; z-index: 1004; background: #1f1f1f">
        <div
        class="card-categoria container"
        style="padding: 0 !important;"
        :class="{
          'corporativo-trick-fix-margin':
            siteName == 'corporativo.bilheteria.com.br',
        }">
          <div class="container menu pt-2 pb-0 text-left">
            <div class="categories-title-container">
              <h3 style="display: inline-block;">Explore nossas categorias e escolha o evento que mais lhe agrada</h3>
              <p class="mb-0 pb-0" style="margin-top: 10px;;">
                Eventos disponíveis: {{slideData.length}}
              </p>
            </div>
            <div class="row items-menu" style="margin: 0px; justify-content: center;margin-left: -7px; z-index: 1004;">
              <button v-for="(item, idx) in categoriesList" :key="idx"
              @click="searchValue = item.name_cat; renderAgain()"
              style="margin: 12px; margin-left: 0px; padding: 0.5rem 2.2rem;color: rgb(0, 0, 0);width: auto;" 
              class="text  btn btn-primary mt-3 mb-4 shop_button_event" >{{item.name_cat}}</button>
            </div>
          </div>
      </div>
      </section>
      <!-- FILTRO CATEGORIAS -->
      <section class="to-block fdb_result" :class="{'custom-corporative-games-template': siteName === 'corporativo.bilheteria.com.br' && !isMobile && ['GAMES', 'GAM', 'GAME'].includes(searchValue.toUpperCase())}">
        <div
          :class="{
            'topo-resultado': siteName !== 'corporativo.bilheteria.com.br',
          }"
          class="container"
        >
          <div class="row">
            <div class="col-sm-12 pb-1 text-left mt-4">
              <h3 class="result__container mb-1">
                Resultados encontrados para:
                <span class="result__description">"{{ searchValue }}"</span>
                <br />
              </h3>
              <p>
                <span class="txt-result-encontr mt-3 pt-3"
                  >{{ countEvents }} {{ textForCount }}</span
                >
              </p>
            </div>
          </div>
          <div class="row">
            <div :style="{'margin-top': siteName === 'corporativo.bilheteria.com.br' && !isMobile && ['GAMES', 'GAM', 'GAME'].includes(searchValue.toUpperCase()) ? '90px !important': ''}"
             class="col-sm-12 pb-1 text-left mt-4"
             v-if="['GAMES', 'GAM', 'GAME'].includes(searchValue.toUpperCase())">
              <h4 class="result__container mb-1"> Todos os jogos são gratuitos. Para Jogar, basta baixá-los, se cadastrar e seguir as instruções.
                Se necessário, é possivel adquirir acessórios e habilidades adicionais através da opção de compra conforme print.
              </h4>
            </div>
            <card-event
              v-for="(item, index) in searchResults"
              :key="index"
              :item="item"
            ></card-event>
          </div>
        </div>
      </section>

      <!-- circos do brasil default banner when searching for circos -->
      <section>
        <div class="container-fluid container__select circos-banner-corporativo" v-if="checkIfSearchingCircos && siteName == 'corporativo.bilheteria.com.br'">
          <div class="container p-0">
            <div class="row">
              <div class="col-12 col-xl-12 text-left">
                <div class="p-3 container-image-banner">
                  <div class="container__arrows" style="margin-top: auto">
                    <div class="swiper-button-prev swiper-button-prev-banner" slot="button-prev"></div>
                    <div class="swiper-button-next swiper-button-next-banner" slot="button-next"></div>
                  </div>
                  <swiper :options="swiperOptionBanner">
                    <swiper-slide v-for="(item, index) in circosDefaultBanner" :key="index">
                      <img class="img-fluid rounded-0 discovery" v-bind:src="(item.imageURI)" :alt="item.title" @click="goto('discovery', item.link)">
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
    <RotateSquareLoader v-if="siteName == 'corporativo.bilheteria.com.br'" :loading="loading" squareColor="#fdc80a"></RotateSquareLoader>
  </div>
</template>

<script>
import Vue from "vue";
import VueHead from "vue-head";
import Logo from "@/components/App-logo.vue";
import config from "@/config";
import { func } from "@/functions";
import { searchService } from "@/components/common/services/search";
import SearchItemLoader from "@/components/loaders/SearchItemLoader.vue";
import AppSearch from "@/components/App-search.vue";
import CardEvent from "@/components/Card-event.vue";
import CardGenreSwiper from "@/components/Card-genre-swiper.vue";

import CardRecommended from "@/components/Card-recommended.vue";
import { eventService } from "@/components/common/services/event";
import { genresService } from "@/components/common/services/genres";
import { discoveryService } from "@/components/common/services/discovery";
import CorporateDefaultBanner from "@/components/CorporateDefaultBanner";
import RotateSquareLoader from "@/components/loaders/RotateSquareLoader";

Vue.use(VueHead);

export default {
  name: "search",
  components: {
    CardGenreSwiper,
    SearchItemLoader,
    Logo,
    AppSearch,
    CardEvent,
    CardRecommended,
    CorporateDefaultBanner,
    RotateSquareLoader
  },
  head: {
    title: function () {
      return {
        inner: `${this.readableRoute.name} / ${this.readableRoute.type}`,
        separator: " - ",
        complement: config.info.title,
      };
    },
    meta: function () {
      return [
        {
          name: "application-name",
          id: "application-name",
          content: this.metaObj.appName,
        },
        {
          name: "description",
          id: "description",
          content: this.metaObj.description,
        },
        {
          name: "keywords",
          id: "keywords",
          content: this.metaObj.keywords,
        },
        {
          itemprop: "name",
          id: "name",
          content: this.metaObj.name,
        },
        {
          itemprop: "description",
          id: "itempropdescription",
          content: this.metaObj.description,
        },
        {
          name: "twitter:card",
          id: "twitter_card",
          content: this.metaObj.twitter.card,
        },
        {
          name: "twitter:title",
          id: "twitter_title",
          content: this.metaObj.twitter.title,
        },
        {
          name: "twitter:description",
          id: "twitter_description",
          content: this.metaObj.twitter.description,
        },
        {
          name: "twitter:image",
          id: "twitter_image",
          content: this.metaObj.twitter.image.root,
        },
        {
          name: "twitter:image:alt",
          id: "twitter_imagealt",
          content: this.metaObj.twitter.image.alt,
        },
        {
          property: "og:title",
          id: "og_title",
          content: this.metaObj.og.title,
        },
        {
          property: "og:type",
          id: "og_type",
          content: this.metaObj.og.type,
        },
        {
          property: "og:url",
          id: "og_url",
          content: this.metaObj.og.url,
        },
        {
          property: "og:description",
          id: "og_description",
          content: this.metaObj.og.description,
        },
        {
          property: "og:site_name",
          id: "og_site_name",
          content: this.metaObj.og.site_name,
        },
        {
          property: "og:image",
          id: "og_image",
          content: this.metaObj.og.image.root,
        },
        {
          property: "og:image:secure_url",
          id: "og_image_secure_url",
          content: this.metaObj.og.image.secure_url,
        },
        {
          property: "og:image:type",
          id: "og_image_type",
          content: this.metaObj.og.image.type,
        },
        {
          property: "og:image:alt",
          id: "og_image_alt",
          content: this.metaObj.og.image.alt,
        },
      ];
    },
    link: function () {
      return [
        {
          rel: "icon",
          href: `${config.info.favicoPath}/favicon-32x32.png`,
          sizes: "32x32",
          type: "image/png",
        },
        {
          rel: "icon",
          href: `${config.info.favicoPath}/favicon-16x16.png`,
          sizes: "16x16",
          type: "image/png",
        },
      ];
    },
  },
  data() {
    return {
      metaObj: this.metatag_getObj(),
      filterCards: false,
      filterLists: true,
      searchResults: [],
      searchValue: "",
      isLoaded: false,
      itau: false,
      loading: false,
      discoveryBanner: [],
      discovery: [],
      slideData: [],
      getGenreList: [],
      siteName: config.info.siteName,
      circosDefaultBanner: [{ imageURI: '/assets/images/circosdobrasilcorporativo.png', link: 'https://circosdobrasil.com/', title: 'Circos do Brasil' }],
      swiperOptionBanner: {
        //loop: true,
        autoplay: true,
        speed: 1000,
        //loopedSlides: 1,
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next-banner",
          prevEl: ".swiper-button-prev-banner",
        },
        breakpoints: {
          800: {
            slidesPerView: 1,
          },
        },
      },
      swiperOptionGenresWithoutImages: {
        autoplay: false,
        slidesPerView: 8,
        slidesPerGroup: 2,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next-genres-without-text",
          prevEl: ".swiper-button-prev-genres-without-text",
        },
        breakpoints: {
          800: {
            slidesPerView: 4,
          },
          600: {
            slidesPerView: 3,
          },
        },
      },
    };
  },
  mixins: [func],
  computed: {
    isMobile() {
      return window.innerWidth <= 760;
    },
    checkIfSearchingCircos () {
      return this.searchValue.toLowerCase() === "circos";
    },
    categoriesList() {
      return this.$store.state.categoriesList
    },
    textForCount() {
      return this.countEvents == 1
        ? "resultado encontrado"
        : "resultados encontrados";
    },
    countEvents() {
      return this.searchResults.length;
    },
    readableRoute() {
      let type = "";
      let name = this.searchValue;
      switch (this.$router.currentRoute.name) {
        case "search":
          type = "Busca";
          break;
        case "search_bycity":
        case "search_bycity_without_search":
          type = "Busca por Cidade";
          break;
        case "search_bystate":
          type = "Busca por Estado";
          break;
        case "search_bylocal":
        case "search_bylocal_without_search":
          type = "Busca por Local";
          break;
        case "search_bygenre":
          type = "Busca por Gênero";
          break;
        case "search_bycategory":
          type = "Busca por Categoria";
          break;
      }
      return {
        name,
        type,
      };
    },
    getGenreDesc() {
      const query = this.$route.query;
      const id_genre = query.id_genre ? query.id_genre : null;
      const genres = this.$store.state.genresList;
      if (genres && genres.length > 0) {
        const find = genres.find((item) => item.id == id_genre);
        return find ? find.name : "";
      }
      return "";
    },
    getCityDesc() {
      const query = this.$route.query;
      const id_municipio = query.id_municipio ? query.id_municipio : null;
      const cities = this.$store.state.cityList;
      if (cities && cities.length > 0) {
        const find = cities.find((item) => item.id_municipio == id_municipio);
        return find ? find.ds_municipio : "";
      }
      return "";
    },
    getDateWhenDesc() {
      const query = this.$route.query;
      const date_when = query.date_when ? query.date_when : null;
      if (date_when) {
        const date_array = date_when.split("-");
        const date = new Date(date_array[0], date_array[1] - 1, date_array[2]);
        const day = String(date.getDate()).padStart(2, "0");
        const day_week = date.toLocaleDateString("pt-br", { weekday: "long" });
        let month_name = date.toLocaleString("pt-br", { month: "long" });
        month_name = month_name.charAt(0).toUpperCase() + month_name.slice(1);
        return `${day} de ${month_name} (${day_week})`;
      }
      return "";
    },
    getAmountDesc() {
      const query = this.$route.query;
      const amount = query.amount ? query.amount : null;
      if (amount == "15") return "Até R$ 15,00";
      if (amount == "25") return "Até R$ 25,00";
      if (amount == "30") return "Até R$ 30,00";
      if (amount == "50") return "Até R$ 50,00";
      if (amount == "1000") return "Tanto Faz";
      return null;
    },
  },
  methods: {
    redirectTo (to) {
      window.location = '/busca/categoria/' + encodeURI(to)
      // this.$router.push({name: 'search_bycategory', params: {input: to}})
    },
    getDiscovery() {
      discoveryService.list(this.key).then(
        (response) => {
          response.forEach(x => {
            if (x.type && x.type === 'card') {
              x.img = x.imageURI
              x.genreName = 'PROMOÇÕES'
              x.id_genre = '0002'
              x.datas = ''
              x.ds_evento = x.title
              if (this.searchValue === 'PROMOÇÕES') {
                this.searchResults.push(x)
              } else {
                this.slideData.push(x)
              }
            }
            this.discoveryBanner = response.filter((x) => x.type == "banner");
          });
          this.loading = false
        },
        (error) => {
          this.loading = false
          this.toastError("Falha na execução.");
        }
      );
    },
    getGenres() {
      genresService.get().then(
        (response) => {
          if (response !== null) {
            response.push({id: '0002', name: 'PROMOÇÕES'})
            this.getGenreList = response.sort((a, b) => {
              if (a.name > b.name) {
                return 1;
              }
              if (a.name < b.name) {
                return -1;
              }
              return 0;
            });
          }
        },
        (error) => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );
    },
    getListResultAgain() {
      eventService.list(this.locale.city.name, this.locale.state.name).then(
        (response) => {
          this.slideData = response.filter(
            (x) => x.id_genre !== undefined && x.id_genre !== null
          );
          this.hideWaitAboveAll();
        },
        (error) => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );
    },
    getListResults(callback) {
      this.getLocation(this.getListResultAgain);

      eventService.list(this.locale.city.name, this.locale.state.name).then(
        (response) => {
          this.slideData = response.filter(
            (x) => x.id_genre !== undefined && x.id_genre !== null
          );

          this.hideWaitAboveAll();
          this.isLoaded = true;

          if (callback !== null && callback !== undefined) {
            callback();
          }
        },
        (error) => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );
    },
    toggleFilter: function (type) {
      if (type === "lists") {
        this.filterCards = true;
        this.filterLists = false;
      } else {
        this.filterLists = true;
        this.filterCards = false;
      }
    },
    goto(item, link) {
      if (this.checkIfSearchingCircos) return window.open(link, '_blank');
      this.$router.push(item.uri);
    },
    getSearchResults(type, input, id_genre, id_municipio, date_when, amount) {
      if (input == "") return;
      this.loading = true
      this.searchResults = {}
      searchService
        .searchPage(
          type,
          input,
          null,
          null,
          null,
          null,
          id_genre,
          id_municipio,
          date_when,
          amount
        )
        .then(
          (response) => {
            if (!response.error) {
              this.searchResults = response;
              this.searchResults.map((x) => (x.img = x.cardimage));
            }
            this.getDiscovery();
            //console.log(response);
            this.isLoaded = true;
            this.loading = false
          },
          (error) => {
            this.loading = false
            this.toastError("Falha na execução.");
          }
        );
    },
    createMetaObj() {
      this.metaObj.appName = config.info.siteName;
      this.metaObj.description = config.info.meta_description;
      this.metaObj.keywords = config.info.meta_keywords;
      this.metaObj.name = config.info.siteName;
      this.metaObj.twitter.card = "summary_large_image";
      this.metaObj.twitter.title = config.info.siteName;
      this.metaObj.twitter.description = config.info.meta_description;
      this.metaObj.twitter.image.root = config.info.logo;
      this.metaObj.twitter.image.alt = config.info.siteName;
      this.metaObj.og.title = config.info.siteName;
      this.metaObj.og.type = "website";
      this.metaObj.og.url = config.host;
      this.metaObj.og.description = config.info.meta_description;
      this.metaObj.og.site_name = config.info.siteName;
      this.metaObj.og.image.root = config.info.logo;
      this.metaObj.og.image.secure_url = config.info.logo;
      this.metaObj.og.image.type = "image/jpeg";
      this.metaObj.og.image.alt = config.info.siteName;
      this.$emit("updateHead");
    },
    getInfoLocal() {
      searchService.getInfoLocal(this.searchValue).then(
        (response) => {
          this.infoLocal = response;
        },
        (error) => {
          this.toastError("Falha na execução.");
        }
      );
    },
    renderAgain() {
      const query = this.$route.query;
      const id_genre = query.id_genre ? query.id_genre : null;
      const id_municipio = query.id_municipio ? query.id_municipio : null;
      const date_when = query.date_when ? query.date_when : null;
      const amount = query.amount ? query.amount : null;
      this.createMetaObj();
      if (
        this.$router.currentRoute.name == "search_bylocal" ||
        this.$router.currentRoute.name == "search_bylocal_without_search"
      )
        this.getInfoLocal();
      this.getSearchResults(
        this.$router.currentRoute.name,
        this.searchValue,
        id_genre,
        id_municipio,
        date_when,
        amount
      );
      this.getListResults();
      this.getGenres();
      window.scrollTo({ top: 450, behavior: 'smooth' });
      if (this.isMobile && this.siteName == "corporativo.bilheteria.com.br") {
        window.scrollTo({ top: 200, behavior: 'smooth' });
      }
    },
  },
  created() {
    this.searchValue = this.$route.params.input ? this.$route.params.input : "";
    this.renderAgain();
  },
  filters: {
    truncate: function (text, length, clamp) {
      clamp = clamp || "...";
      var node = document.createElement("div");
      node.innerHTML = text;
      var content = node.textContent;
      return content.length > length
        ? content.slice(0, length) + clamp
        : content;
    },
    replace: function (message, toReplace, replacement) {
      return message.replace(toReplace, replacement);
    },
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      value = value.toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-genre-search {
  margin-right: -15px;
  margin-left: -15px;
  @media only screen and (max-width: 760px) {
    margin: 0px;
  }
}
.corporativo-trick-fix-margin {
  @media only screen and (max-width: 760px) {
    margin-top: 0px !important;
  }
}

  @media screen and (max-width: 515px) {
    .fix-position-corporativo {
      top: 0px !important;
    }
    .container.menu {
      .categories-title-container {
        h3 {
          font-size: 17px;
        }
        p {
          font-size: 15px;
        }
      }
      .items-menu {
        flex-direction: column;
        height: 60px;
        overflow: auto;
        .shop_button_event {
          width: auto !important;
          height: 35px !important;
          font-size: 9px !important;
          font-weight: bold;
          margin: 5px !important;
          padding: 5px !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
.custom-corporative-games-template {
  /*background-image: url(/assets/game-background.png) !important;
  background-repeat: no-repeat !important;
  background-size: 'contain' !important;*/
}
@media screen and (max-width: 450px) {
  .circos-banner-corporativo {
    .container-image-banner {
      padding: 0 !important;
    }
    .text-left {
      padding: 0.5rem;
    }
    .swiper-slide-active {
      width: 100% !important;
    }
  }
}
</style>
