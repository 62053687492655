<template>
  <div  v-if="event.loaded"  id="cartaz-container">
      <h1 id="event-location-to-print">{{event.ds_local_evento}}</h1>
      <img style="width: 100%;" :src="event.img" alt  />
      <div style="width: 50%;float: left;">
        <h1 id="event-name-to-print">{{event.NomPeca}}</h1>
        <textarea id="custom-date-to-print" v-model="customDate"></textarea>
        <div>
          <p id="event-description-to-print" v-if="event.loaded" ref="eventdesc">
            <span class="descriptions mt-0 p-0 mt-2 pt-2" v-html="event.description"></span>
          </p>
        </div>
      </div>
      <div style="width: 50%;display: grid;">
        <div class="event-price">
          <span>De R$</span>
          <input class="input-poster-color" v-model="customPrice1">
        </div>
         <div class="event-price">
          <span>Por R$</span>
          <input class="input-poster-color" v-model="customPrice2">
        </div>
        <div id="section-qrcode">
          
          <div>
            <span  id="buyNow">Para comprar é só ler o QR Code</span>
            <qrcode-vue class="hide-to-print qr-code-to-print" size="300" :value="$route.query.q" />
            <qrcode-vue class="show-to-print qr-code-to-print" size="500" :value="$route.query.q" />
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import config from "@/config";
import { func } from "@/functions";

import { eventService } from "@/components/common/services/event";

export default {
  name: "cartaz",
  mixins: [func],
  components: {
    QrcodeVue,
  },
  head: {
    title: function () {
      return {
        inner: this.event.loaded
          ? `${this.event.NomPeca} - ${this.event.ds_local_evento} - ${this.event.TipPeca}`
          : "Carregando...",
        separator: " - ",
        complement: this.event.loaded ? config.info.title : "",
      };
    },
    link: function () {
      return [
        {
          rel: "icon",
          href: `${config.info.favicoPath}/favicon-32x32.png`,
          sizes: "32x32",
          type: "image/png",
        },
        {
          rel: "icon",
          href: `${config.info.favicoPath}/favicon-16x16.png`,
          sizes: "16x16",
          type: "image/png",
        },
      ];
    },
  },
  data() {
    return {
      customPrice1: '00,00',
      customPrice2: '00,00',
      customDate: 'Sextas-Feiras e Sábados as 21 h',
      siteName: config.info.siteName,
      processing: true,
      metaObj: this.metatag_getObj(),
      event: {
        loaded: false,
        NomPeca: null,
        CodPeca: null,
        TipPeca: null,
        CenPeca: null,
        ds_local_evento: null,
        id_base: null,
        address: null,
        valores: null,
        description: "",
        img: null,
        badge: [],
        promo: [],
        city: null,
        state: null,
        cityBadgeText: null,
        ontixsme: false,
      },
    };
  },
  methods: {
    getEvent() {
      this.showWaitAboveAll();
      this.processing = true;
      let isCI = false;
      let eventKey = this.key;

      eventService.description(eventKey, isCI).then(
        (response) => {
          this.hideWaitAboveAll();
          this.processing = false;

          if (response.error) {
            this.toastError(response.msg);
            if (response.goto == "home") window.location = "/";
            return;
          }
          if (this.validateJSON(response)) {
            console.log('response', response)
            this.event.loaded = true;
            this.event.NomPeca = response.NomPeca;
            this.event.CodPeca = response.CodPeca;
            this.event.TipPeca = response.TipPeca;
            this.event.CenPeca = response.CenPeca;
            this.event.ds_local_evento = response.ds_local_evento;
            this.event.address = response.address;
            this.event.id_base = response.id_base;
            this.event.valores = response.valores;
            this.event.description = response.description;
            this.event.img = response.img;
            this.event.badge = response.badge;
            this.event.promo = response.promo;
            this.event.meta_keyword = response.meta_keyword;
            this.event.meta_description = response.meta_description;
            this.event.city = response.ds_municipio;
            this.event.state = response.sg_estado;
            this.event.cityBadgeText = response.badge_city_text;
            this.event.nameSite = response.name_site;
            this.event.showPartnerInfo = response.show_partner_info;
            this.event.ontixsme = response.ontixsme;

            this.metaObj.appName = config.info.siteName;
            this.metaObj.description = this.event.meta_description;
            this.metaObj.keywords = this.event.meta_keyword;
            this.metaObj.name = this.event.NomPeca;
            this.metaObj.twitter.card = "summary_large_image";
            this.metaObj.twitter.title = this.event.NomPeca;
            this.metaObj.twitter.description = this.event.meta_description;
            this.metaObj.twitter.image.root = this.event.img;
            this.metaObj.twitter.image.alt = this.event.NomPeca;
            this.metaObj.og.title = this.event.NomPeca;
            this.metaObj.og.type = "website";
            this.metaObj.og.url = config.host.endsWith("/")
              ? config.host
              : config.host + "/" + response.key;
            this.metaObj.og.description = this.event.meta_description;
            this.metaObj.og.site_name = config.info.siteName;
            this.metaObj.og.image.root = this.event.img;
            this.metaObj.og.image.secure_url = this.event.img;
            this.metaObj.og.image.type = "image/jpeg";
            this.metaObj.og.image.alt = this.event.NomPeca;

            this.$emit("updateHead");

            this.imageLoaded = true;
          }
        },
        (error) => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );
    },
  },
  created () {
    const style = document.createElement('style');
    style.innerHTML = `
      p {
        margin: 0;
        line-height: 1;
        color: inherit;
      }
      .header, footer {
        display: none !important;
      }
    `;
    document.head.appendChild(style);
  },
  mounted () {
    console.log('this.$router', this.$router.currentRoute)
    this.getEvent();
    this.keepalive();
  },
  computed: {
    key() {
      return this.$route.params.key;
    },
  },
};
</script>

<style lang="scss" scoped>
#cartaz-container {
  background-color: white;
  text-align: center;
}
#cartaz-container {
  background: white;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0 auto;
  padding: 10px 25px;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  box-sizing: border-box;
}
#event-location-to-print {
  font-size: 1.1cm;
  line-height: 1;
  overflow: hidden;
  height: 40px;
}
#event-name-to-print {
  font-size: 1.3cm;
  line-height: 1;
  font-weight: 200;
  margin: 10px;
  text-align: center;
  overflow: hidden;
  height: 100px;
  padding: 3px;
}
#custom-date-to-print {
  border: 1px dashed gray;
  color: #aaceb8;
  font-size: 1cm;
  text-align: center;
  width: 95%;
  line-height: 1; 
  font-weight: 600;
}
#event-description-to-print {
  height: 454px;
  overflow: hidden;
  margin: 10px;
  padding: 10px;
  background: #aaceb8;
}
.event-price {
  font-size: 1.1cm;
  line-height: 1.2; display: flex; justify-content: flex-start;padding: 10px;
  & span {
    font-weight: 600;
    margin: 10px;
  }
  & input {
    border: 1px dashed gray;
    font-weight: 600;
    width: 42%;
    text-align: center;
  }
}
#section-qrcode {
  display: flex;
  margin-top: 14px;
  width: 87%;
  margin: 0 auto;
}
#buyNow {
  font-size: 0.8cm;
  line-height: 1;
  display: block;
  text-align: start;
  width: 75%;
}
.qr-code-to-print {
  text-align: start;
  margin-top: 16px;
}
.input-poster-color {
  color: #444;
}

.show-to-print {
  display: none;
}
.hide-to-print {
  display: block;
}
@media print {
  // ajustando para os navegadores interpretarem as cores
  *{ color-adjust: exact;  -webkit-print-color-adjust: exact; print-color-adjust: exact; }
  body {
    margin: 0;
    padding: 0;
  }
  #event-description-to-print {
    height: 24cm !important;
    & span {
      font-size: 0.7cm !important;
    }
  }
  input, textarea {
    border: none !important;
  }
  .event-price {
    font-size: 1.8cm !important;
  }
  #buyNow {
    font-size: 1.28cm !important;
  }
  #event-name-to-print {
    font-size: 2cm !important;
    height: 4cm;
  }
  #event-location-to-print {
    font-size: 1.8cm;
    overflow: hidden;
    height: 60px !important;
  }
  #custom-date-to-print {
    font-size: 1.8cm;
  }
  #cartaz-container {
    margin: 0;
    padding: 0;
    width: 30cm;
    height: 44.5cm;
    overflow: hidden;
  }
  .show-to-print {
    display: block;
  }
  .hide-to-print {
    display: none;
  }
  @page :footer {
    display: none
  }

  @page :header {
    display: none
  }
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>