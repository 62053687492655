<template>
  <div>
    <cartazcompreingressos v-if="template == 'compreingressos'"></cartazcompreingressos>
    <cartazingressaria v-if="template == 'ingressaria'"></cartazingressaria>
    <cartazticketHub v-if="template == 'ticketHub'"></cartazticketHub>
    <cartazticketHubSearchHeader v-if="template == 'ticketHubSearchHeader'"></cartazticketHubSearchHeader>
    <cartazticketHubVideoBackground v-if="template == 'ticketHubVideo'"></cartazticketHubVideoBackground>
    <cartaztixsme v-if="template == 'tixsme'"></cartaztixsme>
    <cartazbilheteria v-if="template == 'bilheteria'"></cartazbilheteria>
    <cartazcircosDoBrasil v-if="template == 'circosDoBrasil'"></cartazcircosDoBrasil>
  </div>
</template>

<script>
import Vue from "vue";
import config from "@/config";
import { func } from "@/functions";
import cartazcompreingressos from "./templates/compreingressos/Cartaz";
import cartazingressaria from "./templates/ingressaria/Cartaz";
import cartazticketHub from "./templates/ticketHub/Cartaz";
import cartazticketHubSearchHeader from "./templates/ticketHubSearchHeader/Cartaz";
import cartazticketHubVideoBackground from "./templates/ticketHubVideoBackground/Cartaz";
import cartaztixsme from "./templates/tixsme/Cartaz";
import cartazbilheteria from "./templates/bilheteria/Cartaz";
import cartazcircosDoBrasil from "./templates/circosDoBrasil/Cartaz";

export default {
  name: "event",
  mixins: [func],
  components: {
    cartazcompreingressos,
    cartazingressaria,
    cartazticketHub,
    cartazticketHubSearchHeader,
    cartazticketHubVideoBackground,
    cartaztixsme,
    cartazbilheteria,
    cartazcircosDoBrasil
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {},
  computed: {}
};
</script>

<style lang="scss" scoped>
</style>
