<template>
    <div v-if="isMobile" class="status-bar-message" :class="{ 'showBanner': showBanner, 'hideBanner': !showBanner }">
        <i @click="hideMessage" class="fa fa-times-circle icon-close"
            style="position: absolute; top: 6px;  font-size: 16px; cursor: pointer;color: black;left: 10px;"></i>
        <div style="width: 60%;">
            <p style="margin: 0px; font-size: 0.9rem; color: white;font-weight: bold;">App Bilheteria.com</p>
            <p style="margin: 0;font-size: 0.8rem;color: white;">Descubra eventos incríveis onde quer que esteja!
            </p>
        </div>
        <div @click="redirectToAppStore" class="download-app">Baixar</div>
    </div>
</template>
<script>
export default {
    name: 'DownloadApp',
    data() {
        return {
            isMobile: false,
            showBanner: true,
        };
    },
    mounted() {
        this.isMobile = this.isMobileDevice();
    },
    methods: {
        isMobileDevice() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },
        hideMessage() {
            this.showBanner = false;
        },
        redirectToAppStore() {
            const userAgent = navigator.userAgent.toLowerCase();
            const isAndroid = /android/.test(userAgent);
            const isIOS = /iphone|ipad|ipod/.test(userAgent);

            // Determine the appropriate app store link based on the device platform
            const appStoreLink = isAndroid
                ? 'https://play.google.com/store/apps/details?id=com.bilheteria.entretenimentos'
                : isIOS
                    ? 'https://apps.apple.com/us/app/bilheteria-entretenimentos/id1636326264'
                    : '';

            if (appStoreLink) {
                // Redirect the user to the appropriate app store link
                window.location.href = appStoreLink;
            }
        },
    },
};
</script>
<style scoped>
@media screen and (max-width: 820px) {
    .status-bar-message {
        position: relative;
        top: 10px;
        left: 0;
        right: 0;
        padding: 10px;
        background-color: #333;
        color: #fff;
        text-align: center;
        z-index: 9000000000000;
        display: flex;
        height: 111px;
        background: linear-gradient(90deg, rgb(243, 143, 43) 0%, rgb(232, 65, 105) 100%);
        align-items: center;
        justify-content: space-around;
        border-radius: 1rem;
        margin: 0 1rem;
        box-shadow: 5px 5px 0px 0px #f38d2c;
        margin-bottom: 18px;
    }

    .showBanner {
        animation: slideDown 0.5s forwards;
    }

    .hideBanner {
        animation: slideUp 1s forwards;
    }

    .download-app {
        width: 90px;
        background: white;
        border: medium;
        font-size: 0.8rem;
        font-weight: 200;
        color: black;
        border-radius: 0.3rem;
        padding: 0.3rem;
        box-shadow: 4px 5px 0px 0px #212121;
        border: 2px solid #040404;
        font-weight: bold;
    }

    @keyframes slideDown {
        from {
            top: -100px;
            /* Start offscreen */
        }

        to {
            top: 10;
            /* Slide down to top of viewport */
        }
    }

    @keyframes slideUp {
        from {
            top: 10;
            /* Start offscreen */
        }

        to {
            top: -200px;
            position: absolute;
            /* Slide down to top of viewport */
        }
    }
}
</style>