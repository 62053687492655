import { render, staticRenderFns } from "./DownloadApp.vue?vue&type=template&id=5c4fb664&scoped=true&"
import script from "./DownloadApp.vue?vue&type=script&lang=js&"
export * from "./DownloadApp.vue?vue&type=script&lang=js&"
import style0 from "./DownloadApp.vue?vue&type=style&index=0&id=5c4fb664&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c4fb664",
  null
  
)

component.options.__file = "DownloadApp.vue"
export default component.exports