<template>
  <div v-if="loaded" class="container__ask_code">
    <div class="row mb-4 container-icon-desktop"></div>
    <!-- <div class="container-icon-mobile">
      <div class="col">
        <div id="icon-bilheteria" style="text-align: center">
          <img src="/assets/logo_bilheteria_animado1.gif" />
        </div>
      </div>
    </div> -->
    <section class="to-block">
      <div
        class="container"
        style="
          z-index: 9999;
          position: absolute;
          max-width: 100%;
          background: #000;
        "
      >
        <div
          class="row justify-content-center"
          style="margin: 0px; background: #000"
        >
          <div class="col-md-10 col-lg-10 col-sm-12 text-center">
            <div class="to-box to-touch pt-4">
              <form class="form-signin">
                <div>
                  <div class="col">
                    <div id="icon-bilheteria">
                      <img src="/assets/logo-form.png" />
                    </div>
                  </div>
                  <h2
                    class=""
                    style="
                      max-width: 100% !important;
                      font-size: 15px;
                      color: #fff;
                      margin: 0 auto;
                      margin-bottom: 6px;
                    "
                  >
                    Se sua empresa não está conveniada ao programa corporativo de
                    entretenimentos que oferece aos funcionários e/ou
                    colaboradores várias categorias de entretenimentos e
                    condições especiais para você curtir com seus familiares
                    e/ou colegas de trabalho, peça para o representante
                    autorizado entrar em contato conosco para que possamos gerar
                    o código que dará acesso aos benefícios exclusivos.
                  </h2>
                  <div class="col-md-12">
                    <div class="row">
                      <!-- diireita -->
                      <div
                        class="container-form-fields col-sm-6 col-md-6 col-lg-6"
                        style="justify-content: center"
                      >
                        <!-- primeiro -->
                        <div
                          class="leftcontact col-12 col-md-12"
                          style="
                            display: flex;
                            justify-content: center;
                            margin: 0 auto;
                          "
                        >
                          <input
                            type="text"
                            v-model="form.requester"
                            v-on:keyup.enter="signin"
                            placeholder="Nome do Responsável"
                            required
                            v-bind:class="{
                              errorinputuser: !validateinfo.pass,
                              'form-control': true,
                            }"
                          />
                          <span
                            class=""
                            style="
                              text-align: center;
                              padding: 10px;
                              margin-left: -26px;
                            "
                            title="CEP"
                          >
                            <i class="fa fa-male"></i>
                          </span>
                        </div>
                        <!-- segundo -->
                        <div
                          class="leftcontact col-12 col-md-12"
                          style="
                            display: flex;
                            justify-content: center;
                            margin: 0 auto;
                          "
                        >
                          <input
                            type="text"
                            v-model="form.office"
                            v-on:keyup.enter="signin"
                            placeholder="Cargo"
                            required
                            v-bind:class="{
                              errorinputuser: !validateinfo.pass,
                              'form-control': true,
                            }"
                          />
                          <span
                            class=""
                            style="
                              text-align: center;
                              padding: 10px;
                              margin-left: -26px;
                            "
                            title="CEP"
                          >
                            <i class="fa fa-male"></i>
                          </span>
                        </div>
                        <div
                          class="leftcontact col-12 col-md-12"
                          style="
                            display: flex;
                            justify-content: center;
                            margin: 0 auto;
                          "
                        >
                          <input
                            type="text"
                            v-model="form.company"
                            v-on:keyup.enter="signin"
                            placeholder="Nome da Empresa"
                            required
                            v-bind:class="{
                              errorinputuser: !validateinfo.pass,
                              'form-control': true,
                            }"
                          />
                          <span
                            class=""
                            style="
                              text-align: center;
                              padding: 10px;
                              margin-left: -26px;
                            "
                            title="CEP"
                          >
                            <i class="fa fa-male"></i>
                          </span>
                        </div>
                        <div
                          class="leftcontact col-12 col-md-12"
                          style="
                            display: flex;
                            justify-content: center;
                            margin: 0 auto;
                          "
                        >
                          <input
                            type="text"
                            v-model="form.email"
                            v-on:keyup.enter="signin"
                            placeholder="Email"
                            required
                            v-bind:class="{
                              errorinputuser: !validateinfo.pass,
                              'form-control': true,
                            }"
                          />
                          <span
                            class=""
                            style="
                              text-align: center;
                              padding: 10px;
                              margin-left: -33px;
                            "
                            title="CEP"
                          >
                            <i class="fa fa-envelope"></i>
                          </span>
                        </div>

                        <div
                          class="leftcontact col-12 col-md-12"
                          style="display: inline-flex; justify-content: center"
                        >
                          <div
                            class="small-ddd leftcontact col-md-3 col-lg-3"
                            style="
                              display: flex;
                              padding: 0px;
                              margin-right: 0px;
                            "
                          >
                            <input
                              type="text"
                              v-model="form.ddd"
                              v-on:keyup.enter="signin"
                              placeholder="DDD *"
                              required
                              v-bind:class="{
                                errorinputuser: !validateinfo.pass,
                                'form-control': true,
                              }"
                            />
                          </div>

                          <div
                            class="leftcontact col-md-9 col-lg-9 col-sm-10"
                            style="display: flex; padding: 0px"
                          >
                            <input
                              type="text"
                              style="width: 99%"
                              v-model="form.phone"
                              v-on:keyup.enter="signin"
                              placeholder="Celular *"
                              required
                              v-bind:class="{
                                errorinputuser: !validateinfo.pass,
                                'form-control': true,
                              }"
                            />
                            <span
                              class=""
                              style="
                                text-align: center;
                                padding: 10px;
                                margin-left: -33px;
                              "
                              title="CEP"
                            >
                              <i class="fa fa-phone"></i>
                            </span>
                          </div>
                        </div>

                        <div
                          class="leftcontact col-12 col-md-12"
                          style="
                            display: flex;
                            justify-content: center;
                            margin: 0 auto;
                          "
                        >
                          <input
                            type="text"
                            v-model="form.contributors"
                            v-on:keyup.enter="signin"
                            placeholder="Número de Colaboradores"
                            required
                            v-bind:class="{
                              errorinputuser: !validateinfo.pass,
                              'form-control': true,
                            }"
                          />
                          <span
                            class=""
                            style="
                              text-align: center;
                              padding: 10px;
                              margin-left: -26px;
                            "
                            title="CEP"
                          >
                            <i class="fa fa-male"></i>
                          </span>
                        </div>
                      </div>
                      <!-- esquerda -->

                      <div
                        class="container-form-fields col-sm-6 col-md-6 col-lg-6"
                        style="justify-content: center"
                      >
                        <!-- primeiro -->
                        <div
                          class="leftcontact col-12 col-md-12"
                          style="
                            display: flex;
                            justify-content: center;
                            margin: 0 auto;
                          "
                        >
                          <input
                            type="text"
                            v-model="form.cep"
                            v-on:keyup.enter="signin"
                            placeholder="CEP *"
                            required
                            v-bind:class="{
                              errorinputuser: !validateinfo.pass,
                              'form-control': true,
                            }"
                          />
                          <span
                            class=""
                            style="
                              text-align: center;
                              padding: 10px;
                              margin-left: -32px;
                            "
                            title="CEP"
                          >
                            <i class="fa fa-map-marker-alt"></i>
                          </span>
                        </div>
                        <!-- segundo -->
                        <div
                          class="leftcontact col-12 col-md-12"
                          style="
                            display: flex;
                            justify-content: center;
                            margin: 0 auto;
                          "
                        >
                          <input
                            type="text"
                            v-model="form.address"
                            v-on:keyup.enter="signin"
                            placeholder="Endereço *"
                            required
                            v-bind:class="{
                              errorinputuser: !validateinfo.pass,
                              'form-control': true,
                            }"
                          />
                          <span
                            class=""
                            style="
                              text-align: center;
                              padding: 10px;
                              margin-left: -33px;
                            "
                            title="CEP"
                          >
                            <i class="fa fa-envelope"></i>
                          </span>
                        </div>

                        <div
                          class="leftcontact col-12 col-md-12"
                          style="
                            display: flex;
                            justify-content: center;
                            margin: 0 auto;
                          "
                        >
                          <input
                            type="text"
                            v-model="form.city"
                            v-on:keyup.enter="signin"
                            placeholder="Cidade"
                            required
                            v-bind:class="{
                              errorinputuser: !validateinfo.pass,
                              'form-control': true,
                            }"
                          />
                          <span
                            class=""
                            style="
                              text-align: center;
                              padding: 10px;
                              margin-left: -30px;
                            "
                            title="CEP"
                          >
                            <i class="fa fa-building"></i>
                          </span>
                        </div>

                        <div
                          class="leftcontact col-12 col-md-12"
                          style="display: flex; justify-content: center"
                        >
                          <select
                            class="leftcontact form-control"
                            style="appearance: none"
                            v-model="form.city_state"
                          >
                            <!-- <option value selected>Estado</option> -->
                            <option selected value="undefined">Estado</option>
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espirito Santo</option>
                            <option value="EX">Exterior</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                          </select>

                          <span
                            class="icon-case"
                            style="
                              text-align: center;
                              padding: 10px;
                              margin-left: -29px;
                            "
                            title="Estado"
                          >
                            <i class="fa fa-location-arrow"></i>
                          </span>
                        </div>

                        <div
                          class="leftcontact col-12 col-md-12"
                          style="
                            display: flex;
                            justify-content: center;
                            margin: 0 auto;
                          "
                        >
                          <input
                            type="text"
                            v-model="form.branches"
                            v-on:keyup.enter="signin"
                            placeholder="Filiais em"
                            required
                            v-bind:class="{
                              errorinputuser: !validateinfo.pass,
                              'form-control': true,
                            }"
                          />
                          <span
                            class=""
                            style="
                              text-align: center;
                              padding: 10px;
                              margin-left: -26px;
                            "
                            title="CEP"
                          >
                            <i class="fa fa-male"></i>
                          </span>
                        </div>

                        <div
                          class="leftcontact col-12 col-md-12"
                          style="
                            display: flex;
                            justify-content: center;
                            margin: 0 auto;
                          "
                        >
                          <input
                            type="text"
                            v-model="form.question1"
                            v-on:keyup.enter="signin"
                            placeholder="Como você soube desse programa?"
                            required
                            v-bind:class="{
                              errorinputuser: !validateinfo.pass,
                              'form-control': true,
                            }"
                          />
                          <span
                            class=""
                            style="
                              text-align: center;
                              padding: 10px;
                              margin-left: -26px;
                            "
                            title="CEP"
                          >
                            <i class="fa fa-male"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    style="
                      color: black;
                      margin: 5px auto;
                      max-width: 315px;
                      border: none;
                      background: #fdc80a !important;
                    "
                    class="btn btn-success mb-4 btn-block"
                    type="button"
                    @click="enviarConvenio"
                  >
                    <span v-if="processing">Aguarde...</span>
                    <span v-if="!processing">Enviar</span>
                  </button>
                  <h2
                    class="txts-resetcode-bilheteria"
                    style="max-width: 580px"
                  >
                    Um dos nossos representantes entrará em contato para
                    concluir o convênio com este programa corporativo de
                    entretenimentos e depois é só se divertir.
                    <p class="mt-3" style="color: #fff; font-size: 14px">
                      Para saber mais sobre esse programa.
                      <a href="#" id="" @click="$router.push('')"
                        >CLIQUE AQUI!</a
                      >
                      <a
                        href="#"
                        id="cancel_reset"
                        class="text-left"
                        style="display: block"
                        @click="$router.push('RequestCode')"
                      >
                        <i class="fas fa-angle-left"></i> Voltar
                      </a>
                    </p>
                  </h2>
                  <!-- <a
                    href="#"
                    id="cancel_reset"
                    class="text-left"
                    style="display: block"
                    @click="$router.push('RequestCode')"
                  >
                    <i class="fas fa-angle-left"></i> Voltar
                  </a> -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import VModal from "vue-js-modal";
import config from "@/config";
import { func } from "@/functions";
import { authService } from "@/components/common/services/auth";
import { corporateService } from "@/components/common/services/corporate";
import { partnerService } from "@/components/common/services/partner";

// import { fblogin } from "@/components/directive/fblogin";

Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
});

export default {
  mixins: [func],
  name: "FormCompany",
  components: {},
  directives: {},
  computed: {},
  head: {
    title: function () {
      return {
        inner: "",
        separator: " ",
        complement: "Programa Corporativo Bilheteria.com",
      };
    },
  },
  methods: {
    enviarConvenio() {
      if (
        !this.form.requester ||
        !this.form.office ||
        !this.form.company ||
        !this.form.email ||
        !this.form.ddd ||
        !this.form.phone ||
        !this.form.contributors ||
        !this.form.cep ||
        !this.form.address ||
        !this.form.city ||
        !this.form.city_state ||
        !this.form.branches ||
        !this.form.question1
      ) {
        this.toastError("Campos em brancos");
      }
      if (this.processing) return false;

      this.processing = true;
      this.$wait.start("inprocess");
      this.showWaitAboveAll();

      corporateService
        .enviarConvenio(this.form)
        .then((response) => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocess");
          console.log(response);
          if (response.success) {
            this.toastSuccess("Email enviado");
            this.form = {};
          }

          if (response.error == true || response.code == 404) {
            this.toastError(response.msg);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getinfo() {
      this.processing = true;
      partnerService.getinfo().then(
        (response) => {
          this.loaded = true;
          this.processing = false;
          if (this.validateJSON(response)) {
            //console.log(response);
            this.initfb(response.hasfb, response.fb_appid);
          }
        },
        (error) => {
          this.loaded = true;
          this.processing = false;
        }
      );
    },
  },
  data() {
    return {
      processing: false,
      loaded: false,
      showwatch: "login",
      iscreatedvisible: true,
      hasfb: false,
      token: "",
      validateinfo: {
        login: true,
        pass: true,
      },
      form: {
        auth_type: "",
        login: "",
        pass: "",
      },
    };
  },
  mounted() {
    this.$modal.hide("login");
  },
  created() {
    this.ls_remove("fb_connect");
    this.getinfo();

    switch (window.location.pathname) {
      case "/loginandshopping/printafter":
        this.iscreatedvisible = false;
        break;
    }

    // this.getWindow.fbAsyncInit = function() {
    // }
  },
};
</script>

<style lang="scss">
/* body {
  background: #000 !important;
} */
.container__ask_code {
  .container-icon-mobile {
    display: none;
  }
  .small-ddd {
    width: 100%;
  }
  .container-icon-desktop {
    display: block;
    position: absolute;
    left: 0;
    top: 2;
    z-index: 99999;
  }
  #icon-bilheteria {
    height: 195px;
    img {
      height: 185px;
    }
  }
  .to-block .to-box {
    background: #000 !important;
  }
  .to-box:hover {
    box-shadow: none !important;
  }
  .to-block .to-touch {
    border: none;
  }
  .txts-askcode-bilheteria,
  .txts-resetcode-bilheteria {
    font-size: 22px;
    color: #fff;
    margin: 0 auto;
  }

  .txts-askcode-bilheteria {
    width: 260px;
  }

  .txts-resetcode-bilheteria {
    margin-bottom: 6px;
    font-size: 15px;
  }

  /* sign in FORM */
  #logreg-forms {
    width: 312px;
    margin: 10vh auto;
    background-color: #f3f3f3;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  #logreg-forms form {
    width: 100%;
    max-width: 310px;
    padding: 15px;
    margin: auto;
  }

  #logreg-forms .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }

  #logreg-forms .form-control:focus {
    z-index: 2;
  }

  #logreg-forms .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  #logreg-forms .form-signin input[type="password"] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  #logreg-forms .social-login {
    width: 240px;
    margin: 0 auto;
    margin-bottom: 10px;

    &:hover {
      cursor: default;
    }
  }

  #logreg-forms .social-btn {
    font-weight: 100;
    color: white;
    width: 190px;
    font-size: 0.9rem;
  }

  #logreg-forms a {
    display: block;
    padding-top: 10px;
    color: lightseagreen;
  }

  #logreg-form .lines {
    width: 200px;
    border: 1px solid red;
  }

  .notimplemented {
    display: none !important;
  }

  #logreg-forms button[type="submit"] {
    margin-top: 10px;
  }

  #logreg-forms .facebook-btn {
    background-color: #3c589c;
  }

  #logreg-forms .google-btn {
    background-color: #df4b3b;
  }

  #logreg-forms .form-reset,
  #logreg-forms .form-signup {
    display: none;
  }

  #logreg-forms .form-signup .social-btn {
    width: 210px;
  }

  #logreg-forms .form-signup input {
    margin-bottom: 2px;
  }

  .form-signup .social-login {
    width: 210px !important;
    margin: 0 auto;
  }

  .icon-form {
    position: absolute;
    left: 0;
    margin-left: 13px;
  }

  .errorinputuser {
    border-color: #eb0000;
    border-style: solid solid solid solid;
  }

  @media screen and (max-width: 800px) {
    .container-icon-desktop {
      display: none;
    }
    .container-icon-mobile {
      display: block;
    }
    .container-form-fields,
    .container-form-fields div {
      padding: 0px;
    }
    .to-box {
      padding: 5px !important;
    }
    #icon-bilheteria {
      text-align: center;
      height: auto;
      img {
        height: 125px;
      }
    }
  }

  /* Mobile */

  @media screen and (max-width: 500px) {
    #logreg-forms {
      width: 300px;
    }
    .small-ddd {
      max-width: 60px;
    }

    #logreg-forms .social-login {
      width: 200px;
      margin: 0 auto;
      margin-bottom: 10px;
    }

    #logreg-forms .social-btn {
      font-size: 1.3rem;
      font-weight: 100;
      color: white;
      width: 200px;
      height: 56px;
    }

    #logreg-forms .social-btn:nth-child(1) {
      margin-bottom: 5px;
    }

    #logreg-forms .social-btn span {
      display: none;
    }

    #logreg-forms .facebook-btn:after {
      content: "Facebook";
    }

    #logreg-forms .google-btn:after {
      content: "Google+";
    }

    .box-btn {
      width: 285px;
    }

    .txts-resetcode-bilheteria {
      width: 100%;
    }
  }

  .to-block {
    background: none;
    padding-bottom: 0 !important;
  }

  .to-box {
    cursor: default !important;
  }

  a,
  a:hover {
    background: #fdc80a;
    /* background: -webkit-linear-gradient(#ff244f, #ff3d49); */
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .divider {
    margin-top: 20px;
    margin-bottom: 20px;
    font-style: italic;
    font-size: 13px;
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    text-align: center;
    height: 1.5em;
  }

  .divider:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    background: #d6dade;
  }

  .divider:after {
    font-size: 13px;
    color: #b2b9c1;
    content: attr(data-content);
    position: relative;
    display: inline-block;
    padding: 0 0.5em;
    line-height: 1.5em;
    background-color: #ffffff;
  }

  .form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 0.25rem);
    padding: 0.375rem 0.75rem;
    font-size: 0.85em;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.5px solid #eee;
    border-radius: 0.1rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      box-shadow: none;
    }
  }

  #cancel_reset {
    &:hover {
      color: #027175;
      text-decoration: none;
    }
  }

  .wrapper_login {
    .icon-case {
      background: none !important;
      position: absolute;
      right: 0;
      margin-right: 30px;
      margin-top: 8px;
      font-size: 16px;
      color: #d3d3d3;
      i {
        font-size: 0.9em !important;
      }
    }
  }

  #app {
    overflow: hidden !important;
  }
}
.container__ask_code {
  background: #000 !important;
  height: 100%;
}

.container__ask_code .to-block {
  height: 100vh;
}
</style>
