import { render, staticRenderFns } from "./bilheterias-participantes.vue?vue&type=template&id=5a0b0ca4&scoped=true&"
import script from "./bilheterias-participantes.vue?vue&type=script&lang=js&"
export * from "./bilheterias-participantes.vue?vue&type=script&lang=js&"
import style0 from "./bilheterias-participantes.vue?vue&type=style&index=0&id=5a0b0ca4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a0b0ca4",
  null
  
)

component.options.__file = "bilheterias-participantes.vue"
export default component.exports