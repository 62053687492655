import { render, staticRenderFns } from "./EventImageIngressariaLoader.vue?vue&type=template&id=48e4a7bc&"
import script from "./EventImageIngressariaLoader.vue?vue&type=script&lang=js&"
export * from "./EventImageIngressariaLoader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "EventImageIngressariaLoader.vue"
export default component.exports