<template>
    <div class="col-12 col-md-12 col-sm-12 container-request-corporate" style="justify-content: center" :key="updateForm">
      <div class="leftcontact col-12 col-md-12 col-sm-12" v-for="fieldGroup in formFields" :key="fieldGroup[0].value"
        style="display: flex; justify-content: center; margin: 0 auto;align-items: center;">
        <template>
          <template v-for="field in fieldGroup">
            <input :key="field.value" type="text" v-model="form[field.value]" :placeholder="field.text"
              :required="field.required" v-bind:class="{
                errorinputuser: !field.pass,
                'form-control': true,
              }" v-if="!field.isSelect" />
            <v-select class='input_type_select' style='min-width: 35% !important;gap: 3px;' :clearable='false' :key="field.value"
              :searchable='true' v-model="form[field.value]" :placeholder="field.text" :required="field.required"
              v-if="field.isSelect" :options="field.options" v-bind:class="{
                errorinputuser: !field.pass,
              }"></v-select>
          </template>
        </template>
      </div>
      <div class='cp-buttons'>
        <div class='cp-button' style='background: #FF3C66;' @click="$router.push('VerifyAccess')">
          <span>VOLTAR</span>
        </div>
        <div class='cp-button' @click="sendRequest">
          <span>ENVIAR</span>
        </div>
      </div>
      <LottieLoader :loading="processing" squareColor="#fdc80a"></LottieLoader>
    </div>
  </template>
  
  
  <script>
  import Vue from "vue";
  import VModal from "vue-js-modal";
  import config from "@/config";
  import { func } from "@/functions";
  import { externalService } from "@/components/common/services/external";
  import { corporateService } from "@/components/common/services/corporate";
  import axios from "axios"; // Import Axios for API requests
  import vSelect from "vue-select"; // Import Vue Select for dropdowns
  import LottieLoader from "@/components/loaders/LottieLoader";
  
  Vue.use(VModal, {
    dynamic: true,
    injectModalsContainer: true,
  });
  
  Vue.component("v-select", vSelect); // Register Vue Select globally
  
  export default {
    mixins: [func],
    name: "FormRequestCorporate",
    components: {LottieLoader},
    directives: {},
    computed: {},
    head: {
      title: function () {
        return {
          inner: "",
          separator: " ",
          complement: "Programa Corporativo Bilheteria.com",
        };
      },
    },
    methods: {
      async sendRequest() {
        await this.validateEmail();
        // should validate dynamic required fields not empty
        const requiredFields = this.formFields.filter((field) => {
          return field.some((f) => f.required);
        });
        // typeof object or string
        let hasWrongFields = false;
        requiredFields.forEach((fields) => {
          fields.forEach((field) => {
            if (!['email_e'].includes(field.value)) {
              field.pass = Boolean(this.form[field.value]);
              if (typeof this.form[field.value] === 'object') {
                field.pass = Boolean(this.form[field.value].value);
              }
            }
            if (!field.pass) hasWrongFields = true;
          });
        });
        if (hasWrongFields) {
          return this.$root.$emit('message-alert', {title: "Preencha todos os campos obrigatórios"});
        }
        if (this.processing) return false;
        this.processing = true;
        this.$wait.start("inprocess");
        this.showWaitAboveAll();
  
        const res = await corporateService.reenviarAcesso(this.form);
        if (res && res.success) {
          this.$root.$emit('message-alert', { title: res.msg });
        } else {
          this.$root.$emit('message-alert', {title: res.msg });
        }
        this.hideWaitAboveAll();
        this.$wait.end("inprocess");
        this.processing = false;
        this.$router.push("askCode");
      },
      async validateEmail() {
        this.watchEnabled = false;
        const email = this.form.email_e
        if (email === '') {
          this.formFields[0][0].pass = false;
          return;
        }
        this.processing = true;
        this.formFields[0][0].pass = true;
        try {
          const response = await fetch(`https://api.bilheteria.com.br/v1/zerobounce/validar_email.php?email=${email}`);
          const data = await response.json();
  
          // Update the result based on the API response
          const isValid = data.status === 'valid';
          this.formFields[0][0].pass = isValid;
          if (!isValid) {
            this.$root.$emit('message-alert', {message: data.status_message });
          }
        } catch (error) {
          console.error('Error validating email:', error);
          this.formFields[0][0].pass = false;
        }
        this.processing = false;
        this.watchEnabled = true;
      },
      debouncedValidate: function debounce(func, delay) {
        let timer;
        return function () {
          clearTimeout(timer);
          timer = setTimeout(() => {
            func.apply(this);
          }, delay);
        };
      }
    },
    watch: {
      'form.email_e': async function () {
        if (!this.watchEnabled) return;
        this.debouncedValidateEmail()
      }
    },
    data() {
      return {
        updateForm: 0,
        watchEnabled: true,
        formFields: [
          // [
          //   {
          //     text: "Nome *",
          //     value: "nome_n",
          //     type: "text",
          //     required: true,
          //     pass: true
          //   },
          // ],
          [
            {
              text: "Email *",
              value: "email_e",
              type: "text",
              required: true,
              pass: true,
              debounced: true
            },
          ],
          // [
          //   {
          //     text: "Nome da Empresa",
          //     value: "company_c",
          //     type: "text",
          //     required: true,
          //     pass: true
          //   }
          // ],
          // [
          //   {
          //     text: "DDD *",
          //     value: "ddd_d",
          //     type: "text",
          //     required: true,
          //     pass: true
          //   },
          //   {
          //     text: "Celular *",
          //     value: "phone_p",
          //     type: "text",
          //     required: true,
          //     pass: true
          //   },
          // ]
        ],
        processing: false,
        loaded: false,
        showwatch: "login",
        showResetCodeMessage: false,
        form: {
          searchTerm: "",
          corporate_id: "",
        },
      };
    },
    async mounted() {
      this.form.corporate_id = this.$route.params.corporate_id;
      this.debouncedValidateEmail = this.debouncedValidate(this.validateEmail, 2500);
      this.$modal.hide("login");
    },
  };
  </script>
  
  <style scoped lang='scss'>
  .cp-buttons {
    width: 100%;
    display: flex;
    gap: 5px;
  
    .cp-button {
      width: 60%;
      background: #EF892E;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 39px;
      border-radius: 12px;
      cursor: pointer;
  
      span {
        font-weight: bold;
        color: white;
      }
    }
  }
  @keyframes errorinputuser {
    0% {
      transform: translateX(0);
    }
  
    20% {
      transform: translateX(-10px);
    }
  
    40% {
      transform: translateX(10px);
    }
  
    60% {
      transform: translateX(-10px);
    }
  
    80% {
      transform: translateX(10px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  /* Apply the shake animation to the .shake class */
  .errorinputuser {
    border: 2px solid #ef892e !important;
    animation: errorinputuser 0.5s;
    border-radius: 14px;
  }
  
  .form-control {
    margin: 2px;
    border-radius: 12px;
  }
  
  @media screen and (max-width: 820px) {
  
    .div-btn-enviar .btn {
      width: 100% !important;
    }
  }
  </style>
  <style lang='scss'>
  .container-request-corporate {
    padding: 0 !important;
    div {
    padding: 0 !important;
    }
    .vs__open-indicator {
      transform: scale(0.7);
    }
  
    .input_type_select .vs__dropdown-toggle {
      padding: 0px;
      height: 40px;
      font-size: 14px;
      order: none;
      margin: 0px;
      border-radius: 12px;
    }
  }
  </style>