<template>
  <div class="container__ask_code2">
    <!-- Remove the commented-out div -->
    <section style="background-color: black;height: 100vh;">
      <div class="main-corporative-container">
        <div class="cp-header-logo">
          <img class="bilheteria-logo" src="/assets/images/logocorporativobilheteria.png" alt="Bilheteria.com" />
        </div>
        <div class="cp-header-title">
          <h1 class="txts-askcode-bilheteria">Esqueceu o código de acesso ou quer saber se sua empresa está conveniada?</h1>
        </div>
        <div class="cp-description">
          Informe seu e-mail cadastrado e enviaremos um código de acesso para você.
        </div>
        <form-request-code />
      </div>
      <img class="cp-horizontal-desktop-background" src="/assets/images/novo_corporativo/horizontal_wave.svg" />
      <div class="cp-footer"></div>
    </section>
  </div>
</template>
<script>
import Vue from "vue";
import VModal from "vue-js-modal";
import config from "@/config";
import { func } from "@/functions";
import FormRequestCode from "@/components/Form-request-code.vue";

Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
});

export default {
  mixins: [func],
  name: "RequestCode",
  components: { FormRequestCode },
  directives: {},
  computed: {},
  head: {
    title: function () {
      return {
        inner: "",
        separator: " ",
        complement: "Programa Corporativo Bilheteria.com",
      };
    },
  },
  methods: {},
  data() {
    return {
    };
  },
  mounted() {
    this.$modal.hide("login");
  },
};
</script>

<style lang="scss" scoped>
.black-background {
  background-color: black !important;
}

.cp-horizontal-desktop-background {
  position: absolute;
  height: 10vw;
  width: 100%;
  bottom: 0;
}

.main-corporative-container {
  position: relative;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: IBM Plex Sans;
  padding: 20px;
  width: 40%;
  margin: 0 auto;
  min-height: 38vw;

  .cp-description {
    font-size: 1vw;
    margin: 1rem 0;
    color: white;
    text-align: center;
  }

  .bws-link {
    text-decoration: underline;
    color: #FF8F09;
  }

  .bilheteria-logo {
    width: 100%;
  }

  .cp-header-title {
    display: flex;
    justify-content: center;
  }

  .txts-askcode-bilheteria {
    font-size: 1.3vw;
    width: 80vw;
    font-weight: 800;
    color: white;
    text-align: center;
  }

  .cp-ask-code {
    text-align: center;
    margin-top: 2rem;

    .cp-input {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 0 20px;
    }

    .form-control {
      border: 2px solid #FF5946;
      border-radius: 0.4rem;
      width: 100%;
      height: 3rem;
      font-size: 16px;
    }

  }
}

@media screen and (max-width: 820px) {

  .cp-horizontal-desktop-background {
    display: none;
  }

  .main-corporative-container {
    width: 100%;

    .cp-description {
      font-size: 4vw;
    }

    .txts-askcode-bilheteria {
      height: 17vw;
      font-size: 1rem;
    }


  }

  .cp-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('/assets/images/novo_corporativo/wave_mobile.png');
  }

}
</style>