<template>
    <div v-if="loaded" id="cp-lp" class="site-content" role="main">
        <div v-if="!isToRenderValeIngresso">
            <DonwloadApp style='position: fixed;'/>
            <div id='cp-form-wrapper'>
                <div id='cp-form-container'>
                    <div class="cp-header-logo" style="text-align: center;">
                        <img class="bilheteria-logo" src="/assets/images/logocorporativobilheteria.png"
                            alt="Bilheteria.com" style="width: 86%;" />
                    </div>
                    <h3 id="cp-form-title">Bem vindo ao programa corporativo de entretenimentos</h3>
                    <div id="cp-form-input-container">
                        <h3 style="color: white;font-weight: 300;font-size: 1.2rem;">Insira o código de acesso da sua
                            empresa</h3>
                        <div id='cp-input-container'>
                            <input id="cp-input" @input="tweenCss" type='text' v-model="form.pass"
                                placeholder="Informe o código da empresa" @keyup.enter="validateAskCode" required />
                            <img id="cp-button" src="/assets/images/novo_corporativo/arrow_go.png" alt="enviar"
                                @click="validateAskCode" />
                        </div>
                    </div>
                    <div class="cp-body-description">
                        <span class='cp-description'>Esqueceu o código da empresa ou quer saber se ela está conveniada
                            ao
                            programa? - <a href='#' @click="$router.push('verifyAccess')">Clique aqui</a> </span>
                        <br />
                        <span class='cp-description' @click="$router.push('requestBws')">Para saber mais
                            sobre esse programa - <a href='#'>Clique aqui</a>
                        </span>
                    </div>
                </div>
            </div>
            <div class="mouse">
                <div class="mouse-icon">
                    <span class="mouse-wheel"></span>
                </div>
            </div>

            <svg viewBox="0 0 900 10" fill="none" xmlns="http://www.w3.org/2000/svg" id='svg-slider'>
                <path
                    d="M9.89998 6C9.43671 8.28224 7.41896 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.41896 0 9.43671 1.71776 9.89998 4H445.1C445.563 1.71776 447.581 0 450 0C452.419 0 454.437 1.71776 454.9 4H890.1C890.563 1.71776 892.581 0 895 0C897.761 0 900 2.23858 900 5C900 7.76142 897.761 10 895 10C892.581 10 890.563 8.28224 890.1 6H454.9C454.437 8.28224 452.419 10 450 10C447.581 10 445.563 8.28224 445.1 6H9.89998Z"
                    fill="#ffffff" />
                <mask id="mask0_0_1" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="900"
                    height="10">
                    <path
                        d="M9.89998 6C9.43671 8.28224 7.41896 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.41896 0 9.43671 1.71776 9.89998 4H445.1C445.563 1.71776 447.581 0 450 0C452.419 0 454.437 1.71776 454.9 4H890.1C890.563 1.71776 892.581 0 895 0C897.761 0 900 2.23858 900 5C900 7.76142 897.761 10 895 10C892.581 10 890.563 8.28224 890.1 6H454.9C454.437 8.28224 452.419 10 450 10C447.581 10 445.563 8.28224 445.1 6H9.89998Z"
                        fill="#ffffff" />
                </mask>
                <g mask="url(#mask0_0_1)">
                    <rect class="mask" y="-49" x='0' height="99" fill="#ff9701" />
                </g>
            </svg>
            <section id="panels" style="overflow: hidden;" :key='pageLoaded'>
                <div id="panels-container" :style="{ width: this.dynamicWidth }">
                    <article id="panel-1" class="panel full-screen gradient-green">
                        <div class="container-panel">
                            <div style="width: 100%;height: 100%;">
                                <video id="background-video" style="width: 100%;" autoplay muted loop playsinline>
                                    <source v-if="!isMobileDevice" src="../../public/assets/videos/video_full.mp4"
                                        type="video/mp4">
                                    <source v-if="isMobileDevice" src="../../public/assets/videos/video_curto.mp4"
                                        type="video/mp4">
                                    <!-- <source src="https://assets.mubicdn.net/splash-videos/22/1708703870_video_h264_mobile.mp4" type="video/mp4">  -->
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </article>

                    <article v-for="(panel, idx) in panelsToShow" :key='panel.src' :id="'panel-' + (idx + 2)"
                        class="panel full-screen gradient-green">
                        <div class="container-panel">
                            <section class="preloadSection">
                                <div class="deferredImageContainer">
                                    <a v-if="panel.link" style='cursor: pointer;' @click="doSomethingAe(panel.link)">
                                        <img class="deferredImage" width="100%" :src="panel.src"
                                            :data-src="panel['data-src']">
                                    </a>
                                    <img v-else class="deferredImage" width="100%" :src="panel.src"
                                    :data-src="panel['data-src']">
                                </div>
                            </section>
                        </div>
                    </article>
                </div>
            </section>
        </div>
        <!-- <section id="map" class="full-screen gradient-orange"></section> -->
        <ValeIngresso @login-fired-vale-ingresso="form.pass = $event; validateAskCode()" v-else />
        <LottieLoader :loading="processing"></LottieLoader>
    </div>
</template>

<script>
import Vue from "vue";
import VModal from "vue-js-modal";
import config from "@/config";
import { func } from "@/functions";
import { authService } from "@/components/common/services/auth";
import { corporateService } from "@/components/common/services/corporate";
import { discoveryService } from "@/components/common/services/discovery";
import { partnerService } from "@/components/common/services/partner";
import ValeIngresso from "./ValeIngresso.vue";
import DonwloadApp from "./DownloadApp.vue";
import LottieLoader from "@/components/loaders/LottieLoader";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
Vue.use(VModal, {
    dynamic: true,
    injectModalsContainer: true,
});

export default {
    mixins: [func],
    name: "AskCode",
    head: {
        title: function () {
            return {
                inner: "",
                separator: " ",
                complement: "Programa Corporativo Bilheteria.com",
            };
        },
    },
    components: {
        ValeIngresso,
        DonwloadApp,
        LottieLoader
    },
    directives: {},
    methods: {
        startAutoSwiper() {
            this.intervalId = setInterval(() => {
                const idx = (Number(this.currentDot) + 1) % 4;
                this.currentDot = Number(idx) || 1;
            }, 5000); // Change phrase every 3 seconds
        },
        closeloginfather() {
            this.$route.matched[0].instances.default.$parent.modal_close_login();
        },
        async doSomethingAe(link) {
            if (link) {
                window.open(link, "_blank");
            }
        },
        async getLandingPageImages() {
            const discoveries = await discoveryService.list()
            const mobileImages = [];
            const desktopImages = [];
            discoveries.forEach(discovery => {
                if (discovery.type === 'corporativo') {
                    if (discovery.imageDesktop) {
                        desktopImages.push({
                            src: discovery.imageDesktop,
                            'data-src': discovery.imageDesktop,
                            link: discovery.link
                        })
                    }
                    if (discovery.imageMobile) {
                        mobileImages.push({
                            src: discovery.imageMobile,
                            'data-src': discovery.imageMobile,
                            link: discovery.link
                        })
                    }
                }
            })
            this.panelsToShow = this.isMobileDevice ? mobileImages : desktopImages;
            this.pageLoaded++;
        },
        getinfo() {
            this.processing = true;
            partnerService.getinfo().then(
                (response) => {
                    this.loaded = true;
                    this.processing = false;
                    if (this.validateJSON(response)) {
                        //console.log(response);
                        // this.initfb(response.hasfb, response.fb_appid);
                    }
                },
                (error) => {
                    this.loaded = true;
                    this.processing = false;
                }
            );
        },
        tweenCss() {
            const input = document.getElementById("cp-input");
            const button = document.getElementById("cp-button");
            gsap.to(input, {
                fontSize: !this.isMobileDevice ? "2rem" : "1.5rem", // font-size
            });

            gsap.to(button, {
                top: !this.isMobileDevice ? "60px" : "85px",
                fontWeight: "bold",
                opacity: 1,
                duration: 0.2,
            });
        },
        resetTweenCss() {
            const input = document.getElementById("cp-input");
            const button = document.getElementById("cp-button");
            gsap.to(input, {
                fontSize: "1.4rem", // reset font-size
            });
            gsap.to(button, {
                opacity: 0,
                top: "50px",
                fontWeight: "normal",
                duration: 0.2
            });
        },
        setupIdleCheck() {
            // Setup an interval to periodically check for user idle
            this.idleCheckInterval = setInterval(() => {
                const currentTime = Date.now();
                if (currentTime - this.lastInteractionTime > this.idleDuration) {
                    this.autoScrollPage();
                }
            }, 1000); // Check every second

            // Listen for user scroll events to update the last interaction time
            window.addEventListener('scroll', () => {
                this.lastInteractionTime = Date.now();
            }, { passive: true });
        },
        autoScrollPage() {
            // Calculate the total scrollable height
            const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
            // Get the current scroll position
            const currentScroll = document.documentElement.scrollTop;
            // Check if the current scroll position is at or near the bottom of the page
            if (currentScroll >= scrollableHeight - 100) { // Adjust the 100 pixels threshold as needed
                // If at the bottom, scroll back to the top
                gsap.to(window, { scrollTo: 0, duration: 1 });
            } else {
                // If not at the bottom, continue scrolling down
                gsap.to(window, { scrollTo: "+=100", duration: 1 }); // Adjust the scroll amount as needed
            }
        },
        setupScrollTrigger() {
            const mask = document.querySelector(".mask");
            const panelsContainer = document.querySelector("#panels-container");
            const panels = gsap.utils.toArray("#panels-container .panel");

            this.scrollTriggerInstance = gsap.to(panels, {
                xPercent: -100 * (panels.length - 1),
                ease: "none",
                scrollTrigger: {
                    trigger: "#panels-container",
                    pin: true,
                    start: "center center",
                    scrub: 1,
                    snap: !this.isMobileDevice ?
                        {
                            snapTo: 1 / (panels.length - 1),
                            inertia: false,
                            duration: { min: 0.1, max: 0.1 }
                        }
                        :  {
                            snapTo: 1 / (panels.length - 1),
                            inertia: false,
                            duration: { min: 0.4, max: 0.7 }
                        },
                    end: () => "+=" + (panelsContainer.scrollWidth - window.innerWidth)
                }
            });

            this.maskTriggerInstance = gsap.to(mask, {
                scrollTrigger: {
                    trigger: "#panel-1",
                    scrub: 1,
                    start: "top top",
                    end: () => "+=" + (panelsContainer.scrollWidth - window.innerWidth),
                    onUpdate: self => {
                        mask.style.width = `${self.progress * 100}%`;
                    }
                }
            });

            if (this.isMobileDevice) {
                const inputFields = document.getElementById("cp-input");
                const onFocus = () => {
                    this.scrollTriggerInstance.scrollTrigger.disable(false);
                    this.maskTriggerInstance.scrollTrigger.disable(false);
                };
                const onBlur = () => {
                    this.scrollTriggerInstance.scrollTrigger.enable(false);
                    this.maskTriggerInstance.scrollTrigger.enable(false);
                };

                inputFields.addEventListener("focus", onFocus)
                inputFields.addEventListener("blur", onBlur);
                this.inputEventListeners.push({ inputFields, onFocus, onBlur });
            }
        }
    }
    ,
    data() {
        return {
            currentDot: 1,
            intervalId: null,
            processing: false,
            loaded: false,
            messageAlert: "",
            isToRenderValeIngresso: false,
            showwatch: "login",
            iscreatedvisible: true,
            hasfb: false,
            pageLoaded: 0,
            panelsToShow: [],
            idleCheckInterval: null,        // Store interval reference
            idleDuration: 10000,            // 10 seconds
            lastInteractionTime: Date.now(), // Store the last interaction time
            crollTriggerInstance: null, // Store GSAP instance
            maskTriggerInstance: null,    // Store mask GSAP instance
            inputEventListeners: [],       // Store input event listener references
            token: "",
            validateinfo: {
                login: true,
                pass: true,
                // passcode: true,
            },
            form: {
                auth_type: "",
                login: "",
                pass: "",
            },
        };
    },
    watch: {
        'form.pass': function (newVal) {
            if (newVal === '') {
                this.resetTweenCss();
            }
        }
    },
    computed: {
        isMobileDevice() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },
        dynamicWidth() {
            let howMuch = this.panelsToShow.length + 1;
            return `${window.innerWidth * howMuch}px`;
        }
    },
    async mounted() {
        this.$modal.hide("login");
        document.body.classList.add('black-background');
        await this.getLandingPageImages();
        this.$nextTick(async () => {
            await new Promise(resolve => setTimeout(resolve, 500));
            this.setupScrollTrigger();
            this.setupIdleCheck();
        });
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
        clearInterval(this.idleCheckInterval);
        document.body.classList.remove('black-background');

        if (this.scrollTriggerInstance) {
            this.scrollTriggerInstance.scrollTrigger.kill();
            this.scrollTriggerInstance.kill();
        }
        if (this.maskTriggerInstance) {
            this.maskTriggerInstance.scrollTrigger.kill();
            this.maskTriggerInstance.kill();
        }

        // Remove event listeners for input fields
        this.inputEventListeners.forEach(({ input, onFocus, onBlur }) => {
            input.removeEventListener("focus", onFocus);
            input.removeEventListener("blur", onBlur);
        });

        // Clear the references
        this.scrollTriggerInstance = null;
        this.maskTriggerInstance = null;
        this.inputEventListeners = [];

        // Cleanup ScrollTrigger for the component
        ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    },
    created() {
        if (this.$route.query.code) {
            this.$router.push("/");
        }
        const lottie = document.createElement('script')
        lottie.setAttribute('src', 'https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js')
        document.head.appendChild(lottie)

        if (this.$route.name === 'valeIngresso') {
            this.isToRenderValeIngresso = true;
        }

        this.ls_remove("fb_connect");
        this.getinfo();

        switch (window.location.pathname) {
            case "/loginandshopping/printafter":
                this.iscreatedvisible = false;
                break;
        }
    },
};
</script>

<style lang="scss" scoped>
.black-background {
    background-color: black !important;
}

#cp-lp {


    a {
        color: #ff9701;
        text-decoration: underline;
        font-size: 1.4rem;
    }

    .preloadSection {
        font-size: 0;
    }


    #background-video {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    #cp-form-wrapper {
        position: fixed;
        z-index: 3;
        width: 34vw;
        background: #00000069;
        border-radius: 1rem;
        bottom: 2vw;
        left: 4vw;
        height: auto;
        padding: 1rem;
        display: flex;
        justify-content: center;
    }

    #cp-form-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        text-align: center;
    }

    #cp-form-title {
        color: #ff9701;
        font-size: 1.2rem;
        width: 100%;
        text-align: center;
    }

    #cp-form-input-container {
        width: 80%;
        position: relative;
        margin-bottom: 2rem;
        justify-content: center;
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    #cp-input-container {
        justify-content: center;
        min-height: 70px;
        display: flex;
        align-items: center;
        width: 100%;
        #cp-input {
            width: 100%;
            border-radius: 5px;
            border: none;
            text-align: center;
            display: flex;
            padding: 8px;
            outline: none;
            color: white;
            background: transparent;
            border: 1px solid white;
            font-size: 1.4rem;
        }

        #cp-input::placeholder {
            color: #ff9701;
            opacity: 1;
        }

        #cp-button {
            opacity: 0;
            width: 35px;
            padding: 5px;
            margin-left: -41px;
            cursor: pointer;
        }
    }

    .deferredImageContainer {
        display: inline-block;
        position: relative;
        width: 100%;

    }

    .deferredImage {
        width: 100%;
    }

    .newImage {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .full-screen {
        display: block;
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }

    .mouse {
        margin: 16px auto;
        width: 100%;
        position: fixed;
        bottom: 0;
        z-index: 5;
        transform: translateX(50%);
    }

    .mouse-icon {
        width: 25px;
        height: 45px;
        border: 2px solid #ffffff;
        border-radius: 15px;
        cursor: pointer;
        position: relative;
        text-align: center;
    }

    .mouse-wheel {
        height: 12px;
        margin: 2px auto 0;
        display: block;
        width: 3px;
        background-color: #ff9701;
        border-radius: 50%;
        -webkit-animation: 1.6s ease infinite wheel-up-down;
        -moz-animation: 1.6s ease infinite wheel-up-down;
        animation: 1.6s ease infinite wheel-up-down;
    }

    @-webkit-keyframes wheel-up-down {
        0% {
            margin-top: 2px;
            opacity: 0;
        }

        30% {
            opacity: 1;
        }

        100% {
            margin-top: 20px;
            opacity: 0;
        }
    }

    @-moz-keyframes wheel-up-down {
        0% {
            margin-top: 2px;
            opacity: 0;
        }

        30% {
            opacity: 1;
        }

        100% {
            margin-top: 20px;
            opacity: 0;
        }
    }

    @keyframes wheel-up-down {
        0% {
            margin-top: 2px;
            opacity: 0;
        }

        30% {
            opacity: 1;
        }

        100% {
            margin-top: 20px;
            opacity: 0;
        }
    }

    #svg-slider {
        width: 25%;
        transform: rotate(90deg);
        position: fixed;
        right: -9vw;
        z-index: 2;
        top: 50%;
        height: 10px;
    }

    #panels #panels-container {
        height: 100vh;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        padding: 0;
        overflow: hidden;
        background-color: #ddd;
    }

    #panels #panels-container .panel {
        // color: var(--color-just-black);
        position: relative;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        display: flex;
        text-align: left;
        align-items: center;
        justify-content: center;
        // border-right: 1px solid #f00;
    }

    #panels #panels-container .panel .container-panel {
        width: 100%;
        padding: 0;
    }

    #panels #panels-container .panel img {
        max-width: 100%;
        height: 100vh;
        object-fit: cover;
    }

    #panels #panels-container .panel .panels-navigation {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }

    #panels #panels-container .panel .anchor-panel,
    #panels #panels-container .panel .nav-panel {
        text-transform: uppercase;
        margin-right: 2rem;
    }

    #panels #panels-container .panels-navigation {
        position: absolute;
        width: 100%;
        bottom: 2rem;
        right: 2rem;
    }

    .cp-horizontal-desktop-background {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .cp-vertical-desktop-background {
        position: absolute;
        bottom: 0;
        top: 0;
        height: 100%;
        z-index: 99999;
        width: 20%;
    }

    .main-corporative-container {
        background-color: black;
    }

    .cp-body-description {
        color: white;
        width: 80%;
        display: flex;
        gap: 5px;
        flex-flow: column;
        font-size: 1.2rem;
    }

    @media screen and (max-width: 820px) {
        #cp-form-wrapper {
            width: 93%;
            top: 12%;
            max-height: 520px;
        }

        #svg-slider {
            width: 98%;
            top: 2rem;
            height: 10px;
            transform: none;
            left: 4px;
            right: 0;
        }

        .cp-body-description {
            width: 93% !important;
            font-size: 1.1rem;

            span a {
                font-size: 1.2rem;
            }
        }

        #cp-form-container {
            width: 93%;
        }

        #cp-form-input-container {

            h3 {
                font-size: 0.9rem;
            }
        }

        #cp-input::placeholder {
            font-size: 1rem;
        }

        .mouse {
            transform: translateX(46%);
        }

        .preloadSection,
        .deferredImageContainer,
        .deferredImage,
        .container-panel {
            height: 100%;
        }
    }
}
</style>