<template>
  <div class="container__ask_code2">
    <!-- Remove the commented-out div -->
    <section style="background-color: black;">
      <div class="main-corporative-container">
        <div class="cp-header-logo">
          <img class="bilheteria-logo" src="/assets/images/logocorporativobilheteria.png" alt="Bilheteria.com" />
        </div>
        <div class="cp-header-title">
          <h1 class="txts-askcode-bilheteria">Sua empresa ainda não está conveniada</h1>
        </div>

        <div class="cp-description">
            Você tem duas opções para iniciar o processo de formalização do convênio:
            <br/>
            <br/>

            Você pode solicitar ao diretor de Recursos Humanos que preencha o 
            <a href="#" class='bws-link' @click="$router.push({ path: '/requestbws', replace: true})">
              formulário correspondente
            </a> para estabelecer
            o convênio OU se preferir, você pode preencher o cadastro abaixo como pessoa física e participar por conta
            própria.
            <br/>
            <br/>
            Após o preenchimento, nosso representante entrará em contato para fornecer um código de acesso,
            permitindo que você e seus colegas aproveitem as vantagens disponíveis.
        </div>
        <form-request-corporate />
      </div>
      <img class="cp-horizontal-desktop-background" src="/assets/images/novo_corporativo/horizontal_wave.svg" />
      <div class="cp-footer"></div>
    </section>
  </div>
</template>
<script>
import Vue from "vue";
import VModal from "vue-js-modal";
import config from "@/config";
import { func } from "@/functions";
import FormRequestCorporate from "@/components/Form-request-corporate.vue";

Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
});

export default {
  mixins: [func],
  name: "RequestAccess",
  components: { FormRequestCorporate },
  directives: {},
  computed: {},
  head: {
    title: function () {
      return {
        inner: "",
        separator: " ",
        complement: "Programa Corporativo Bilheteria.com",
      };
    },
  },
  methods: {},
  data() {
    return {
    };
  },
  mounted() {
    this.$modal.hide("login");
  },
};
</script>

<style lang="scss" scoped>
.black-background {
  background-color: black !important;
}

.cp-horizontal-desktop-background {
  width: 100%;
  height: 10vw;
}

.main-corporative-container {
  position: relative;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: IBM Plex Sans;
  padding: 20px;
  width: 40%;
  margin: 0 auto;
  .cp-description {
    font-size: 0.9rem;
    margin: 1rem 0;
    color: white;
    text-align: center;
  }

  .bws-link {
    text-decoration: underline;
    color: #FF8F09;
  }

  .bilheteria-logo {
    width: 100%;
  }

  .cp-header-title {
    display: flex;
    justify-content: center;
  }

  .txts-askcode-bilheteria {
    font-size: 1.8rem;
    width: 80vw;
    font-weight: 800;
    color: white;
    text-align: center;
    height: 5vw;
  }

  .cp-ask-code {
    text-align: center;
    margin-top: 2rem;

    .cp-input {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 0 20px;
    }

    .form-control {
      border: 2px solid #FF5946;
      border-radius: 0.4rem;
      width: 100%;
      height: 3rem;
      font-size: 16px;
    }

  }
}

@media screen and (max-width: 500px) {

  .cp-horizontal-desktop-background {
    display: none;
  }

  .main-corporative-container {
    width: 100%;
    .cp-ask-code {
      .cp-description {
        font-size: 1.1rem;
      }

      .cp-input {
        gap: 0.3rem;
      }

      .form-control {
        font-size: 1rem;
      }
    }

    .txts-askcode-bilheteria {
      height: 17vw;
      font-size: 1rem;
    }

    
  }
  .cp-footer {
      width: 100%;
      height: 159px;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('/assets/images/novo_corporativo/wave_mobile.png');
    }

}
</style>