<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12">
            <section
              class="to-block bg__ingressaria to-block-mobile mt-0 pt-1"
              style="padding-top: 80px !important"
            >
              <!-- Banner -->

              <div class="container ingressaria-container mt-5">
                <div class="row">
                  <div class="descr-evento col-12 col-md-8 col-lg-6">
                    <img
                      class="event__card-img img__mobile"
                      :src="event.img"
                      alt
                      style
                    />
                    <EventTitleLoader
                      v-if="!imageLoaded && !roomLoaded && !timeLoaded"
                      :speed="2"
                      :animate="true"
                    ></EventTitleLoader>
                    <div v-else>
                      <h1 class="title mb-2 mt-0">{{ event.NomPeca }}</h1>
                      <span class="event__badges">
                        <a
                          href="#"
                          class="badge badge__icon badge__genre badge-danger noClick"
                          id="badge__gender"
                          @click="gotoSearch(event.TipPeca, 'genre')"
                          >{{ event.TipPeca }}</a
                        >
                        <a
                          href="#"
                          :class="parentalrating(event)"
                          id="badge__age"
                          >{{ event.CenPeca }}</a
                        >
                        <a
                          :href="'/busca/local/' + event.ds_local_evento"
                          class="badge badge__icon badge__state badge-light"
                          id="badge__address"
                          >{{ event.ds_local_evento }}</a
                        >
                        <a
                          :href="'/busca/cidade/' + event.city"
                          class="badge badge__icon badge__city badge-secondary"
                          id="badge__city"
                          >{{ event.cityBadgeText }}</a
                        >
                        <!-- <a href="#" class="badge badge__icon badge__money badge-success noClick" id="badge__price">{{event.valores}}</a> -->
                        <a
                          href="#"
                          class="badge badge__icon badge__partner badge-info noClick"
                          v-if="event.showPartnerInfo === 1"
                          id="badge__price"
                          >Vendido por {{ event.nameSite }}</a
                        >
                        <!-- <a href="#" v-if="imageLoaded" class="badge badge__icon badge__local badge-info" id="badge__map" @click="map($event)">Ver no mapa</a> -->
                        <span class="flag" id>
                          <img
                            v-for="item in event.badge"
                            v-bind:key="item.tag"
                            :id="item.tag"
                            :title="item.tag"
                            :src="item.img"
                            alt
                          />
                          <!-- <img
                            v-for="(ipromo, index) in event.promo"
                            :key="index"
                            :src="ipromo.img"
                            :title="ipromo.tag"
                            :alt="ipromo.tag"
                          /> -->
                        </span>
                      </span>
                      <h3 v-if="siteName" class="detalhes-evento mt-3">
                        Detalhes do evento
                      </h3>

                      <p
                        id="read-more-p"
                        v-if="event.loaded"
                        class="lead mt-0 pt-0"
                        v-bind:class="{ 'read-more-p-limited': showreadmore }"
                        ref="eventdesc"
                      >
                        <span
                          class="event__description mt-0 p-0"
                          :class="{'games-white-description': event.TipPeca === 'games' && siteName == 'games.bilheteria.com.br'}"
                          v-html="event.description"
                        ></span>
                      </p>
                      <div
                        id="read-more"
                        @click="showreadmoreclick"
                        v-if="showreadandless && showreadmore"
                      >
                        <div class="btn to-btn" :class="classBtn">LER MAIS</div>
                      </div>
                      <div
                        id="read-less"
                        @click="showreadmoreclick"
                        v-if="showreadandless && !showreadmore"
                      >
                        <div class="btn to-btn" :class="classBtn">
                          LER MENOS
                        </div>
                      </div>
                      <iframe v-if="event.external_video_uri" class="img__mobile" style="margin: 10px auto !important;" width="100%" height="240" :src="eventVideoFormatter(event.external_video_uri)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 mx-auto col-md-4 col-lg-6">
                    <EventImageIngressariaLoader
                      class="event__card-img"
                      v-if="!imageLoaded && !roomLoaded && !timeLoaded"
                      :speed="2"
                      :animate="true"
                      style="
                        height: 240px;
                        border-radius: 5px;
                        box-shadow: none !important;
                      "
                    ></EventImageIngressariaLoader>
                    <img
                      class="event__card-img"
                      :src="event.img"
                      alt
                      style
                      v-else
                    />
                    <!-- Share -->
                    <hr v-if="event.external_video_uri"
                      class="divider mb-0 mt-2 hide__for__mobile"
                      style="max-width: 460px"
                    />
                    <iframe v-if="event.external_video_uri" class="hide__for__mobile" style="margin: 0 auto;" width="460" height="240" :src="eventVideoFormatter(event.external_video_uri)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <hr
                      data-content="Compartilhar"
                      class="divider mb-0 mt-2"
                      style="max-width: 460px"
                    />
                    <div class="row" id="share">
                      <!-- facebook -->
                      <a class="facebook" :href="linkFacebook" target="blank">
                        <i class="fab fa-facebook-f"></i>
                      </a>

                      <!-- twitter -->
                      <a class="twitter" :href="linkTwitter" target="blank">
                        <i class="fab fa-twitter"></i>
                      </a>

                      <!-- linkedin -->
                      <!-- <a class="linkedin" :href="linkLinkedin" target="blank"><i class="fab fa-linkedin-in"></i></a> -->

                      <!-- pinterest -->
                      <a class="pinterest" :href="linkPinterest" target="blank">
                        <i class="fab fa-pinterest-p"></i>
                      </a>
                      <!-- qr code -->
                      <a
                        style="cursor: pointer; background: #fff"
                        @click="$modal.show('posterModal')"
                        class="twitter"
                      >
                        <i
                          style="display: contents; color: #000"
                          class="fa fa-sm mr-2 fa-qrcode"
                        ></i>
                      </a>
                    </div>
                    <!-- Produtor -->
                    <!-- <div class="col-md-12 col-10 m-sm-auto p-0" style="max-width: 460px;">
                    <h3 class="mt-3">Sobre o parceiro</h3>
                    <div class="row align-items-center ">
                      <div class="col-4 ">
                      </div>

                      <div class="col-12">
                        <h4>Cia de Ingressos</h4>
                        <p class="lead">A cia de ingressos fornece aos nossos clientes uma prestação de serviço diferenciada e específica para controle de venda de ingressos para todo o tipo de evento</p>
                        <a href="#" class="btn to-btn" :class="classBtn"><i class="fa fa-sm mr-2 fa-link"></i>Ir para o site do parceiro</a>
                      </div>
                    </div>
                    </div>-->
                    <!-- Local do evento -->
                    <div
                      class="col-md-12 col-10 m-sm-auto p-0 pt-3 mb-0"
                      style="max-width: 460px"
                    >
                      <LineLoader
                        v-if="!imageLoaded && !roomLoaded && !timeLoaded"
                        :speed="2"
                        :animate="true"
                      ></LineLoader>
                      <span v-else-if="event.TipPeca !== 'games'">
                        <h3 class="local-evento mt-3">Local do evento</h3>
                        <div class="row align-items-center">
                          <div class="col-4"></div>

                          <div class="col-12">
                            <p
                              v-if="siteName != 'www.apetesp.org.br'"
                              class="lead"
                            >
                              {{ this.event.address }}
                            </p>
                            <p
                              v-if="siteName == 'www.apetesp.org.br'"
                              class="lead"
                              style="color: #fff !important"
                            >
                              {{ this.event.address }}
                            </p>
                            <a
                              v-if="siteName != 'www.apetesp.org.br'"
                              href="#"
                              @click="map($event)"
                              class="btn to-btn"
                              :class="classBtn"
                            >
                              <i class="fa fa-sm mr-2 fa-map"></i>Ver mapa
                            </a>
                            <!-- <button 
                              class="fundo-btn btn to-btn"
                              @click="$modal.show('posterModal')"
                              :class="classBtn">
                               <i class="fa fa-sm mr-2 fa-qrcode"></i> Gerar QR Code
                            </button> -->
                            <a
                              v-if="siteName == 'www.apetesp.org.br'"
                              href="#"
                              @click="map($event)"
                              class="btn to-btn"
                              :class="classBtn"
                              style="background-color: #1c98d6 !important"
                            >
                              <i class="fa fa-sm mr-2 fa-map"></i>Ver mapa
                            </a>
                          </div>
                        </div>
                      </span>
                    </div>

                    <!-- Horários -->
                    <!-- <div class="col-md-12 col-10 m-sm-auto p-0" style="max-width: 460px;" v-if="imageLoaded && roomLoaded && timeLoaded">
                      <iframe class="map" :src="returnMap()"
          width="100%" height="300" frameborder="0" style="border:0" allowfullscreen=""></iframe>

                    </div>-->
                  </div>
                </div>
              </div>

              <modal class="posterModal" name="posterModal">
                <p>
                  Informe a senha para continuar
                </p>
                <div class="inputFieldModal">
                  <input class="form-control" 
                  required
                  autofocus v-model="cartazKey" />
                </div>
                <div class="buttonModal">
                  <button :class="classBtn" class="btn to-btn" @click="validateAndRedirect">Confirmar</button>
                </div>
              </modal>
              <!-- <div
                v-if="siteName != 'live.bilheteria.com.br' && siteName != 'ingressoparatodos.com.br'"
                class="btn__comprar"
                id="btn__comprar"
                @click="scrollTo()"
                title="Selecionar horários"
              >
                <i
                  class="fa fa-sm fa-arrow-down"
                  title="Visualizar opções de compra"
                ></i>
                Comprar ingressos
              </div> -->
              <div
                v-if="siteName == 'live.bilheteria.com.br'"
                class="btn__comprar"
                id="btn__comprar"
                @click="scrollTo()"
                title="Selecionar horários"
              >
                <!-- <i class="fa fa-sm fa-shopping-cart"></i> -->
                <i
                  class="fa fa-sm fa-arrow-down"
                  title="Visualizar opções de compra"
                ></i>
                Fazer Doação
              </div>
              <whats-app v-if="siteName == 'ingressoparatodos.com.br'" :siteName="siteName" :redirectUrl="'https://wa.me/551133848045'"></whats-app>
              <div v-if="!event.active_external_buy_button"
                class="container ingressaria-container2 pl-0 mt-0 pt-0 container__calendar"
              >
                <div class>
                  <div class="p-2">
                    <h3
                      v-if="siteName != 'demo.bilheteria.com.br'"
                      class
                      id="horario"
                    >
                      Escolha de Dias e Horários
                    </h3>
                    <p
                      v-if="siteName != 'demo.bilheteria.com.br'"
                      class="txt-select-date mt-1 mb-0 pb-0"
                    >
                      Selecione uma data e um horário para compra
                    </p>
                    <p
                      v-if="siteName == 'demo.bilheteria.com.br'"
                      class="mt-1 mb-0 pb-0"
                    >
                      ADQUIRA JÁ SEU INGRESSO
                    </p>
                    <div class="container__arrows">
                      <div class="swiper-button-prev" slot="button-prev"></div>
                      <div class="swiper-button-next" slot="button-next"></div>
                    </div>
                    <swiper :options="swiperOption" class>
                      <swiper-slide
                        class="p-0"
                        v-for="item in filteredDays"
                        :key="item.HorSessao + item.day"
                        :style="{display: item.exibirDia == 'SIM' ? 'initial': 'none'}"
                      >
                        <div class="c01">
                          <div class="c">
                            <div
                              class="img-fluid rounded-0 col-12 p-0 card__time text-center align-items-center"
                              @click="
                                filteredHours(item.day),
                                  (selected = item.id_apresentacao)
                              "
                              v-bind:class="{
                                'card__time-active':
                                  selected == undefined
                                    ? (selected = item.id_apresentacao)
                                    : item.id_apresentacao == selected,
                              }"
                            >
                              <div>
                                <h3 v-if="item.weekdayName == 'sex'" style="text-transform: uppercase">
                                  Sexta
                                </h3>
                                <h3
                                  v-else-if="
                                    item.istoday == 1 &&
                                    siteName != 'demo.bilheteria.com.br'
                                  "
                                  style="text-transform: uppercase"
                                >
                                  HOJE
                                </h3>
                                <h3
                                  v-else-if="
                                    item.istomorrow == 1 &&
                                    siteName != 'demo.bilheteria.com.br'
                                  "
                                  style="text-transform: uppercase"
                                >
                                  AMANHÃ
                                </h3>
                                <h3
                                  v-else-if="
                                    siteName != 'demo.bilheteria.com.br'
                                  "
                                  style="text-transform: uppercase"
                                >
                                  {{ item.weekdayName }}

                                </h3>
                                <p
                                  v-if="siteName != 'demo.bilheteria.com.br'"
                                  class="lead"
                                >
                                  {{ item.day }}
                                </p>
                              </div>

                              <!-- mudança demobilheteria botão 01-->

                              <div>
                                <h3
                                  v-if="siteName == 'demo.bilheteria.com.br'"
                                  style="text-transform: uppercase"
                                >
                                  {{ item.day }}
                                </h3>
                                <p
                                  v-if="siteName == 'demo.bilheteria.com.br'"
                                  class="lead"
                                >
                                  {{ item.HorSessao }}
                                </p>
                              </div>

                              <!-- fim mudança demobilheteria botão 01 -->
                              <i
                                style="visibility: hidden"
                                class="icon-active fa fa-caret-down"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </swiper-slide>
                    </swiper>
                    <div class="container__available-times pl-3 row">
                      <div
                        v-for="item in listOfHours"
                        :key="item.HorSessao + item.day"
                        style="margin: 5px !important"
                      >
                        <div
                          class="card__hour text-center align-items-center"
                          v-if="item.exibirResultado == 'SIM'"
                        >
                          <div
                            class="text-center align-items-center"
                            @click="buy(item.id_apresentacao, event.ontixsme)"
                          >
                            <h3
                              v-if="siteName != 'demo.bilheteria.com.br'"
                              class="lead"
                            >
                              <i
                                class="far fa-sm fa-clock"
                                style="margin-right: 5px; font-size: 15px"
                              ></i>
                              {{ item.HorSessao }}
                            </h3>
                            <p class="lead">
                              <!-- <span class="card__hour-icon">R$</span>
                              {{item.ValPeca | moneyIngressaria }} --->
                              <i
                                class="card__hour-icon fa fa-shopping-cart"
                              ></i>
                              <span
                                class="text-comprar"
                                v-if="siteName != 'live.bilheteria.com.br'"
                                >&nbsp;&nbsp;COMPRAR</span
                              >
                              <span
                                class="text-comprar"
                                v-if="siteName == 'live.bilheteria.com.br'"
                                >&nbsp;&nbsp;DOAR</span
                              >
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div @click="buyByUri(event.external_buy_uri)" v-if="event.active_external_buy_button && event.external_buy_uri"
                class="container ingressaria-container2 pl-0 mt-0 pt-0" style="margin-top: 28px !important;" >
              <div class="container__available-times pl-3 row" >
                        <div
                          class="card__hour text-center align-items-center" style="width: 145px !important;"
                        >
                          <div
                            class="text-center align-items-center"
                          >
                            <p class="lead">
                              <i
                                class="card__hour-icon fa fa-shopping-cart"
                              ></i>
                              <span
                                class="text-comprar"
                                >&nbsp;&nbsp;{{ event.name_btn_buy || 'COMPRAR' }}</span
                              >
                            </p>
                          </div>
                        </div>
              </div>
              </div>
              <div v-for="item in filteredDays" 
                :key="item.HorSessao + item.day"
                @click="buy(item.id_apresentacao, event.ontixsme)"
                :style="{display: event.active_external_buy_button && !event.external_buy_uri  ? '': 'none'}"
                class="container ingressaria-container2 pl-0 mt-0 pt-0" style="margin-top: 28px !important;" >
              <div class="container__available-times pl-3 row" >
                        <div
                          class="card__hour text-center align-items-center" style="width: 145px !important;"
                        >
                          <div
                            class="text-center align-items-center"
                          >
                            <p class="lead">
                              <i
                                class="card__hour-icon fa fa-shopping-cart"
                              ></i>
                              <span 
                                class="text-comprar"
                                >&nbsp;&nbsp;{{ event.name_btn_buy || 'COMPRAR' }}</span
                              >
                            </p>
                          </div>
                        </div>
              </div>
              </div>
              <div class="container">
                <div class="row ingressaria-container3">
                  <div class="col-sm-12 pb-1 text-left mt-4">
                    <h3
                      style="font-size: 17px"
                      class="txt-outros-eventos result__container mb-1"
                    >
                      Outros eventos na sua região:
                    </h3>
                  </div>
                </div>
                <div class="row">
                  <card-recommended
                    v-for="(item, index) in slideData.slice(0, 9)"
                    :key="index"
                    :item="item"
                  ></card-recommended>
                </div>
              </div>
            </section>
          </div>

          <!-- <div class="col-3">
          a
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueResource from "vue-resource";
import VueHead from "vue-head";
import config from "@/config";
import { func } from "@/functions";
import $ from "jquery";
import EventTimeLoader from "@/components/loaders/EventTimeLoader.vue";
import EventTitleLoader from "@/components/loaders/EventTitleLoader.vue";
import EventRoomLoader from "@/components/loaders/EventRoomLoader.vue";
import LineLoader from "@/components/loaders/LineLoader.vue";
import EventImageLoader from "@/components/loaders/EventImageLoader.vue";
import EventImageIngressariaLoader from "@/components/loaders/EventImageIngressariaLoader.vue";
import AppSearch from "@/components/App-search.vue";
import WhatsApp from '../../WhatsApp.vue'

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

import { eventService } from "@/components/common/services/event";

import CardRecommended from "@/components/Card-recommended.vue";

Vue.use(VueHead);

Vue.filter("money", function (value) {
  let helper = parseFloat(value);
  let ret = "0.00";
  if (helper > 0) {
    ret = helper.toFixed(2);
  }
  return `R$ ${ret}`;
});
Vue.filter("moneyIngressaria", function (value) {
  let helper = parseFloat(value);
  let ret = "0.00";
  if (helper > 0) {
    ret = helper.toFixed(2);
  }
  return `${ret}`;
});

export default {
  name: "event",
  mixins: [func],
  components: {
    EventTimeLoader,
    WhatsApp,
    EventTitleLoader,
    EventRoomLoader,
    EventImageLoader,
    EventImageIngressariaLoader,
    LineLoader,
    AppSearch,
    CardRecommended,
  },
  head: {
    title: function () {
      return {
        inner: this.event.loaded
          ? `${this.event.NomPeca} - ${this.event.ds_local_evento} - ${this.event.TipPeca}`
          : "Carregando...",
        separator: " - ",
        complement: this.event.loaded ? config.info.title : "",
      };
    },
    meta: function () {
      return [
        {
          name: "application-name",
          id: "application-name",
          content: this.metaObj.appName,
        },
        {
          name: "description",
          id: "description",
          content: this.metaObj.description,
        },
        {
          name: "keywords",
          id: "keywords",
          content: this.metaObj.keywords,
        },
        {
          itemprop: "name",
          id: "name",
          content: this.metaObj.name,
        },
        {
          itemprop: "description",
          id: "itempropdescription",
          content: this.metaObj.description,
        },
        {
          name: "twitter:card",
          id: "twitter_card",
          content: this.metaObj.twitter.card,
        },
        {
          name: "twitter:title",
          id: "twitter_title",
          content: this.metaObj.twitter.title,
        },
        {
          name: "twitter:description",
          id: "twitter_description",
          content: this.metaObj.twitter.description,
        },
        {
          name: "twitter:image",
          id: "twitter_image",
          content: this.metaObj.twitter.image.root,
        },
        {
          name: "twitter:image:alt",
          id: "twitter_imagealt",
          content: this.metaObj.twitter.image.alt,
        },
        {
          property: "og:title",
          id: "og_title",
          content: this.metaObj.og.title,
        },
        {
          property: "og:type",
          id: "og_type",
          content: this.metaObj.og.type,
        },
        {
          property: "og:url",
          id: "og_url",
          content: this.metaObj.og.url,
        },
        {
          property: "og:description",
          id: "og_description",
          content: this.metaObj.og.description,
        },
        {
          property: "og:site_name",
          id: "og_site_name",
          content: this.metaObj.og.site_name,
        },
        {
          property: "og:image",
          id: "og_image",
          content: this.metaObj.og.image.root,
        },
        {
          property: "og:image:secure_url",
          id: "og_image_secure_url",
          content: this.metaObj.og.image.secure_url,
        },
        {
          property: "og:image:type",
          id: "og_image_type",
          content: this.metaObj.og.image.type,
        },
        {
          property: "og:image:alt",
          id: "og_image_alt",
          content: this.metaObj.og.image.alt,
        },
      ];
    },
    link: function () {
      return [
        {
          rel: "icon",
          href: `${config.info.favicoPath}/favicon-32x32.png`,
          sizes: "32x32",
          type: "image/png",
        },
        {
          rel: "icon",
          href: `${config.info.favicoPath}/favicon-16x16.png`,
          sizes: "16x16",
          type: "image/png",
        },
      ];
    },
  },
  data() {
    return {
      siteName: config.info.siteName,
      cartazKey: "",
      linkFacebook: "",
      linkTwitter: "",
      linkLinkedin: "",
      linkPinterest: "",
      processing: true,
      filterBy: 0,
      filterByDate: "",
      filterByRoom: "Todas as opções",
      metaObj: this.metatag_getObj(),
      selected: undefined,
      showreadandless: false,
      showreadmore: false,
      slideData: [],
      event: {
        loaded: false,
        NomPeca: null,
        CodPeca: null,
        TipPeca: null,
        CenPeca: null,
        ds_local_evento: null,
        external_video_uri: null,
        external_buy_uri: null,
        active_external_buy_button: null,
        name_btn_buy: null,
        id_base: null,
        address: null,
        valores: null,
        description: "",
        img: null,
        badge: [],
        promo: [],
        city: null,
        state: null,
        cityBadgeText: null,
        ontixsme: false,
      },
      listOfHours: [],
      selectedDay: [],
      dates: [],
      salasDisponiveis: [],
      presentantion: [],
      timeLoaded: false,
      roomLoaded: false,
      dateLoaded: false,
      imageLoaded: false,
      swiperOption: {
        // loop: true,
        // autoplay: true,
        // speed: 1000,
        // loopedSlides: 1,
        slidesPerView: "7",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          800: {
            slidesPerView: "3",
          },
        },
      },
    };
  },
  created() {
    // Fixa navbar ao ultrapassa-lo
    var navbar = $("#navbar"),
      distance = navbar.offset().top,
      $window = $(window);

    $window.scroll(function () {
      if ($window.scrollTop() >= 140) {
        navbar.removeClass("navbar-fixed-top").addClass("navbar-fixed-top");
      } else {
        navbar.removeClass("navbar-fixed-top");
      }

      if ($window.scrollTop() >= 500) {
        $("#btn__comprar").css("opacity", 1 - $(window).scrollTop() / 250);
      } else {
        $("#btn__comprar").css("opacity", 1 + $(window).scrollTop() / 250);
      }

      $("#btn__scroll").css("opacity", 1);
    });
  },
  methods: {
    buyByUri (external_buy_uri) {
      if (this.checkEventAlert(this.event)) return;
      let newUri = external_buy_uri
      if (!newUri.includes('http')) {
        newUri = 'https://' + external_buy_uri
      }
      window.open(newUri, '_blank')
    },
    eventVideoFormatter (url) {
      const videoDefault = 'https://youtube.com/embed/'
      if (url.includes('/watch')) {
        return videoDefault + url.split('?v=')[1]
      } 
      if (url.includes('/embed/')) {
        return url
      }
      return videoDefault + url.split('youtu.be/')[1]
    },
    validateAndRedirect() {
      if (this.cartazKey === "20211511") {
        let routeData = this.$router.resolve({
          name: "cartaz",
          params: { key: this.$route.params.key },
          query: { q: window.location.href },
        });
        window.open(routeData.href, "_blank");
        this.cartazKey = "";
        this.$modal.hide("posterModal");
      } else {
        this.cartazKey = "";
        this.toastError("Tente novamente");
      }
    },
    getListResultAgain() {
      eventService.list(this.event.city, this.locale.state.name).then(
        (response) => {
          this.slideData = response.filter(
            (x) => x.id_genre !== undefined && x.id_genre !== null
          );
          this.hideWaitAboveAll();
        },
        (error) => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );
    },
    getListResults(callback) {
      this.getLocation(this.getListResultAgain);
      eventService.list(this.event.city, this.event.state).then(
        (response) => {
          this.slideData = response.filter(
            (x) =>
              x.id_genre !== undefined &&
              x.id_genre !== null &&
              x.ds_evento !== this.event.NomPeca
          );

          this.hideWaitAboveAll();
          this.isLoaded = true;

          if (callback !== null && callback !== undefined) {
            callback();
          }
        },
        (error) => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );
    },
    escapeHtml(text) {
      var map = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#039;",
      };

      return text.replace(/[&<>"']/g, function (m) {
        return map[m];
      });
    },
    generateSocialLinks() {
      let url = window.location;
      let title = escape(this.event.NomPeca);
      let media = this.event.img;

      this.linkFacebook =
        "https://www.facebook.com/share.php?u=" + url + "&title=" + title;
      this.linkLinkedin =
        "https://www.linkedin.com/shareArticle?mini=true&url=" +
        url +
        "&title=" +
        title +
        "&source=source";
      this.linkTwitter =
        "https://twitter.com/intent/tweet?text=" + title + "&url=" + url;
      this.linkPinterest =
        "https://pinterest.com/pin/create/bookmarklet/?media=" +
        media +
        "&url=" +
        url +
        "&is_video=false&description=";
    },
    showreadmoreclick() {
      if (this.showreadmore) {
        this.showreadmore = false;
      } else {
        this.showreadmore = true;
      }
    },
    setdescription() {
      Vue.nextTick().then((response) => {
        this.showreadandless = this.$refs.eventdesc.clientHeight > 400;
        this.showreadmore = this.showreadandless;
      });
    },
    scrollTo() {
      var element = document.getElementById("horario");
      var top = element.offsetTop;
      window.scrollTo(0, top - 100);
    },
    parentalrating(event) {
      let ret = "badge noClick ";
      switch (event.CenPeca) {
        case "L":
          ret += "badge-prL";
          break;
        case "10":
          ret += "badge-pr10";
          break;
        case "12":
          ret += "badge-pr12";
          break;
        case "14":
          ret += "badge-pr14";
          break;
        case "16":
          ret += "badge-pr16";
          break;
        case "18":
          ret += "badge-pr18";
          break;
      }
      return ret;
    },
    selectedDate(item) {
      this.filterByDate = item.date;
    },
    selectedRoom(item) {
      this.filterBy = item.CodSala;
      this.filterByRoom = item.NomSala;
    },
    toggleFilter(code) {
      this.filterBy = code;
    },
    buy(id_apresentacao, changetotixsme) {
      if (this.checkEventAlert(this.event)) return;
      this.gotoLegacy(id_apresentacao, "shopping", changetotixsme);
    },
    map(event) {
      if (event) event.preventDefault();

      window.open(
        "http://maps.google.com/?q=" +
          (this.event.address == null || this.event.address == ""
            ? this.event.ds_local_evento
            : this.event.address)
      );
    },
    returnMap() {
      //  debugger

      var map =
        "https://www.google.com/maps/embed?q=" +
        encodeURIComponent(this.event.address);
      return map;
    },
    gotoSearch(item, type) {
      switch (type) {
        case "city":
          this.$router.push("/busca/cidade/" + item);
          break;
        case "local":
          this.$router.push("/busca/local/" + item);
          break;
        case "genre":
          this.$router.push("/busca/genero/" + item);
          break;
      }
      //this.$router.push("/busca/" + item);
    },
    getRooms() {
      Vue.nextTick().then((response) => {
        eventService.rooms(this.event.id_base, this.event.CodPeca).then(
          (response) => {
            this.hideWaitAboveAll();
            if (this.validateJSON(response)) {
              this.salasDisponiveis = response;
              this.roomLoaded = true;
            }
          },
          (error) => {
            this.processing = false;
            this.hideWaitAboveAll();
            this.toastError("Falha na execução.");
          }
        );
      });
    },
    getDates() {
      Vue.nextTick().then((response) => {
        eventService.dates(this.event.id_base, this.event.CodPeca).then(
          (response) => {
            this.hideWaitAboveAll();
            if (this.validateJSON(response)) {
              this.dates = response;
              this.dateLoaded = true;
              this.filterByDate = "Datas";
            }
          },
          (error) => {
            this.processing = false;
            this.hideWaitAboveAll();
            this.toastError("Falha na execução.");
          }
        );
      });
    },
    getPresentation(callback) {
      this.showWaitAboveAll();
      Vue.nextTick().then((response) => {
        eventService.presentation(this.event.id_base, this.event.CodPeca).then(
          (response) => {
            this.hideWaitAboveAll();
            if (this.validateJSON(response)) {
              this.presentantion = response;
              this.timeLoaded = true;
              if (callback !== null && callback !== undefined) {
                callback();
                this.getListResults();
              }
            }
          },
          (error) => {
            this.processing = false;
            this.hideWaitAboveAll();
            this.toastError("Falha na execução.");
          }
        );
      });
    },
    trimKeyForId(key) {
      return key.split("-")[0];
    },
    getEvent() {
      this.showWaitAboveAll();
      this.processing = true;
      let isCI = false;
      let eventKey = this.key;

      eventService.description(eventKey, isCI).then(
        (response) => {
          this.hideWaitAboveAll();
          this.processing = false;

          console.log("getEvent:");
          console.log(response);

          if (response.error) {
            this.toastError(response.msg);
            if (response.goto == "home") window.location = "/";
            return;
          }
          if (this.validateJSON(response)) {
            this.event.loaded = true;
            this.event.NomPeca = response.NomPeca;
            this.event.CodPeca = response.CodPeca;
            this.event.TipPeca = response.TipPeca;
            this.event.CenPeca = response.CenPeca;
            this.event.ds_local_evento = response.ds_local_evento;
            this.event.external_video_uri = response.external_video_uri;
            this.event.address = response.address;
            this.event.id_base = response.id_base;
            this.event.valores = response.valores;
            this.event.description = response.description;
            this.event.external_buy_uri = response.external_buy_uri;
            this.event.active_external_buy_button = response.active_external_buy_button;
            this.event.name_btn_buy = response.name_btn_buy;
            this.event.alert_title = response.alert_title;
            this.event.alert_description = response.alert_description;
            this.event.alert_button = response.alert_button;
            this.event.alert_uri = response.alert_uri;
            this.event.alert_enabled = response.alert_enabled;
            this.event.img = response.img;
            this.event.badge = response.badge;
            this.event.promo = response.promo;
            this.event.meta_keyword = response.meta_keyword;
            this.event.meta_description = response.meta_description;
            this.event.city = response.ds_municipio;
            this.event.state = response.sg_estado;
            this.event.cityBadgeText = response.badge_city_text;
            this.event.nameSite = response.name_site;
            this.event.showPartnerInfo = response.show_partner_info;
            this.event.ontixsme = response.ontixsme;

            this.$root.$emit('send-meta-pixel-event', {
              name: 'ViewContent',
              fb_appid: response.fb_appid,
              ga_id: response.ga_id,
              data: {
                content_name: this.event.NomPeca,
                content_category: this.event.TipPeca,
                content_id: eventKey,
                content_type: 'product',
                value: this.event.valores,
              }
            })

            this.getRooms();
            this.getPresentation(this.fillFirstHour);
            this.getDates();
            this.setdescription();
            this.generateSocialLinks();

            this.metaObj.appName = config.info.siteName;
            this.metaObj.description = this.event.meta_description;
            this.metaObj.keywords = this.event.meta_keyword;
            this.metaObj.name = this.event.NomPeca;
            this.metaObj.twitter.card = "summary_large_image";
            this.metaObj.twitter.title = this.event.NomPeca;
            this.metaObj.twitter.description = this.event.meta_description;
            this.metaObj.twitter.image.root = this.event.img;
            this.metaObj.twitter.image.alt = this.event.NomPeca;
            this.metaObj.og.title = this.event.NomPeca;
            this.metaObj.og.type = "website";
            this.metaObj.og.url = config.host.endsWith("/")
              ? config.host
              : config.host + "/" + response.key;
            this.metaObj.og.description = this.event.meta_description;
            this.metaObj.og.site_name = config.info.siteName;
            this.metaObj.og.image.root = this.event.img;
            this.metaObj.og.image.secure_url = this.event.img;
            this.metaObj.og.image.type = "image/jpeg";
            this.metaObj.og.image.alt = this.event.NomPeca;

            this.$emit("updateHead");

            this.imageLoaded = true;
          }
        },
        (error) => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );
    },
    filteredHours(day) {
      if (document.querySelector(".container__available-times")) {
        let hours = this.presentantion.filter((x) => x.day == day);
        document.querySelector(".container__available-times").style.opacity = 0;
        this.listOfHours = this.removeDuplicatesBy((x) => x.HorSessao, hours);
        setTimeout(function () {
          document.querySelector(".container__available-times").style.opacity = 1;
        }, 200);
      }
    },
    fillFirstHour() {
      const firstDateAvailable = this.presentantion.filter(item => item.exibirDia === 'SIM')[0]
      this.selected = firstDateAvailable.id_apresentacao
      this.filteredHours(firstDateAvailable.day);
    },
    removeDuplicatesBy(keyFn, array) {
      var mySet = new Set();
      return array.filter(function (x) {
        var key = keyFn(x).toUpperCase(),
          isNew = !mySet.has(key);
        if (isNew) mySet.add(key);
        return isNew;
      });
    },
  },
  mounted() {
    this.getEvent();
    this.keepalive();
  },
  computed: {
    classBtn() {
      if (this.siteName == "ingressoparatodos.com.br") return "";
      return "dark";
    },
    key() {
      return this.$route.params.key;
    },
    filtered() {
      let ret = this.presentantion;
      if (this.filterBy != 0) {
        ret = this.presentantion.filter((x) => x.CodSala == this.filterBy);
      }
      if (this.filterByDate != "" && this.filterByDate != "Datas") {
        let splited = this.filterByDate.split("/");
        let day = `${splited[0]}/${splited[1]}`;
        let year = splited[2];
        ret = ret.filter((x) => x.day == day && x.year == year);
      }
      return ret;
    },
    filteredDays() {
      let ret = this.presentantion;

      console.log(
        "PHSA - to-site/views/templates/ingressaria/Event.vue -> filteredDays ret: "
      );
      console.log(ret);

      let novoretorno = this.removeDuplicatesBy((x) => x.day, ret);

      console.log(
        "PHSA - to-site/views/templates/ingressaria/Event.vue -> filteredDays novoretorno: "
      );
      console.log(novoretorno);

      return novoretorno;
    },
  },
};

$(document).ready(function () {
  // ready!
  // debugger
});
</script>
<style>
.games-white-description * {
  color: white !important;
  background-color: black !important;
}
</style>
<style lang="scss" scoped>
.card {
  background: #333;
}

.noClick {
  cursor: default;
}

.result__button-group {
  padding-bottom: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}

.badge-prL {
  color: #fff;
  background-color: #28a745;
}

.badge-prL[href]:hover,
.badge-prL[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-pr10 {
  color: #fff;
  background-color: #007bff;
}

.badge-pr10[href]:hover,
.badge-pr10[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-pr12 {
  color: #212529;
  background-color: #ffc107;
}

.badge-pr12[href]:hover,
.badge-pr12[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-pr14 {
  color: #fff;
  background-color: #ed7300;
}

.badge-pr14[href]:hover,
.badge-pr14[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #e5760e;
}

.badge-pr16 {
  color: #fff;
  background-color: #dc3545;
}

.badge-pr16[href]:hover,
.badge-pr16[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-pr18 {
  color: #fff;
  background-color: #343a40;
}

.badge-pr18[href]:hover,
.badge-pr18[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}
</style>