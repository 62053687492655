<template>
  <div class="a">
    <div>
      <section
        v-if="
          infoLocal.id_local_evento > 0 &&
          (this.$router.currentRoute.name == 'search_bylocal' ||
            this.$router.currentRoute.name == 'search_bylocal_without_search')
        "
      >
        <div class="hero-section" :style="stylesHeroSection"></div>

        <div id="eventos" class="content-section" :class="classes">
          <div class="org-id" style="position: absolute;">
            <div class="org-avatar-block">
              <div class="org-avatar-block"><img :src="infoLocal && infoLocal.imageURIBanner2 ? infoLocal.imageURIBanner2 : ''" width="200" height="200" alt="" class="image" style="padding:20px"></div>
            </div>
          </div>
          <div class="container" style="margin-top: 68px;  padding: 0;">
            <p class="lead" style="margin: 10px 0px;font-weight: 600;line-height: 1.3em;font-size: 1.3em">SOBRE A CASA</p>
            <p class="lead convertBindHtmlColor" v-html="infoLocal.meta_description"></p>
            <p class="lead convertBindHtmlColor" v-html="infoLocal.ticketbox_info"></p>
            <p class="lead convertBindHtmlColor" v-html="infoLocal.occupation_info"></p>
            <span v-if="infoLocal.url">
              <div class="row align-items-center">
                <div class="col-12">
                  <a  @click="openSite($event)" :class="classBtn" style="float: left;" href="#" class="txts-evento btn to-btn">
                    <i class="fa fa-sm mr-2 fa-globe"></i>Visitar site
                  </a>
                </div>
              </div>
            </span>
            <span>
              <p style="margin: 10px 0px;font-weight: 600;line-height: 1.3em;font-size: 1.3em" class="lead mt-3">Local</p>
              <div class="row align-items-center">
                <div class="col-12">
                  <p class="lead">
                    {{infoLocal.ds_googlemaps}}
                  </p>
                  <a @click="map($event)" :class="classBtn" style="float: left;" href="#" class="txts-evento btn to-btn">
                    <i class="fa fa-sm mr-2 fa-map"></i>Ver mapa
                  </a>
                </div>
              </div>
            </span>
          </div>
          <!-- <div class="content-wrapper">
            <div class="content-block list-head">
              <h1 class="event-name">{{ infoLocal.ds_local_evento }}</h1>
              <h3 class="event-section-heading">Próximos eventos</h3>
            </div>
          </div> -->
        </div>
      </section>
      <section
        class="to-block to-viewport bg-dark bg__main"
        style
        :style="{'margin-top': siteName === 'games.bilheteria.com.br' && !isMobile ? '40px !important' : ''}" 
        data-block-type="call_to_action"
        data-id="2"
        v-else
      >
        <div
          v-if="siteName != 'www.apetesp.org.br'"
          class="container justify-content-center align-items-center d-flex pt-5 pb-0 mb-0 h-25"
        >
          <div class="col-10 justify-content-center text-center">
            <div v-if="siteName !== 'games.bilheteria.com.br'" class="d-none d-sm-block" style>
              <h2>Deseja encontrar outro evento ?</h2>
            </div>
            <div class="d-none d-sm-block input-group mt-4 mb-0 p-1 w-100" :style="{'padding-top': siteName === 'games.bilheteria.com.br'? '35px !important' : ''}">
              <app-search></app-search>
            </div>
          </div>
        </div>
      </section>
      <!-- PROPAGANDA -->
      <div class="to-block fdb_result" v-if="discoveryBanner.length > 0 && ['Bilheteria.com.br', 'demo.bilheteria.com.br'].indexOf(siteName) > -1">
        <div class="container p-0">
          <div class="row">
            <div class="col-12 col-xl-12 text-left">
              <div class="p-3">
                <div class="container__arrows" style="margin-top: auto">
                  <div class="swiper-button-prev swiper-button-prev-banner" slot="button-prev"></div>
                  <div class="swiper-button-next swiper-button-next-banner" slot="button-next"></div>
                </div>
                <swiper :options="swiperOptionBanner">
                  <swiper-slide v-for="(item, index) in discoveryBanner" :key="index">
                    <img class="img-fluid rounded-0 discovery" v-bind:src="(item.imageURI)" :alt="item.title" @click="goto('discovery', item.link)">
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- PROPAGANDA -->
      <!-- FILTRO CATEGORIAS -->
      <section  v-if="categoriesList && categoriesList.length > 0 && ['Bilheteria.com.br', 'demo.bilheteria.com.br'].indexOf(siteName) > -1"
          class="to-block fdb_result"
          style="padding: 0 !important; position: sticky; top: 0px; z-index: 1004">
        <div
        class="card-categoria container"
        style="padding: 0 !important;">
          <div class="container menu pt-2 pb-0 text-left">
            <div class="categories-title-container">
              <h3 style="display: inline-block;">Explore nossas categorias e escolha o evento que mais lhe agrada</h3>
              <p class="mb-0 pb-0" style="margin-top: 10px;;">
                Eventos disponíveis: {{slideData.length}}
              </p>
            </div>
            <div class="row items-menu" style="margin: 0px; justify-content: center;margin-left: -7px; z-index: 99999;">
              <button
              onclick="window.location='https://corporativo.bilheteria.com.br/askCode '"
              style="margin: 12px 12px 12px 0px;padding: 0.5rem 1.9rem;width: auto;font-size: 14px;" 
              class="text  btn btn-primary mt-3 mb-4 shop_button_event">CORPORATIVO</button>
              <button v-for="(item, idx) in categoriesList" :key="idx"
              @click="searchValue = item.name_cat; renderAgain()"
              style="margin: 12px 12px 12px 0px;padding: 0.5rem 1.9rem;width: auto;font-size: 14px;" 
              class="text btn btn-primary mt-3 mb-4 shop_button_event" >{{item.name_cat}}</button>
            </div>
          </div>
      </div>
      </section>
      <!-- FILTRO CATEGORIAS -->
    <SearchByPrice v-if="this.$router.currentRoute.name === 'search' && siteName == 'ingressoparatodos.com.br'"></SearchByPrice>
      <section class="to-block fdb_result" :class="classes">
        <div class="container">
          <div class="row text-search-result">
            <div class="op1 col-sm-12 pb-1 text-left mt-4" :style="{'margin-top': siteName === 'games.bilheteria.com.br' && !isMobile ? '217px !important' : ''}">
              <h3
                class="result__container mb-1"
                v-if="!infoLocal.id_local_evento"
              >
                Resultados encontrados para:
                <span class="result__description"
                  >"{{ searchValue }}
                  {{ getGenreDesc ? `- Gênero: ${getGenreDesc}` : "" }}
                  {{ getCityDesc ? `- Onde: ${getCityDesc}` : "" }}
                  {{ getDateWhenDesc ? `- Quando: ${getDateWhenDesc}` : "" }}
                  {{ getAmountDesc ? `- Quanto: ${getAmountDesc}` : "" }}
                  {{ getCategorysDesc ? `- Quanto: ${getCategorysDesc}` : "" }}"</span
                >
                <br />
              </h3>
              <h3 v-if="infoLocal.id_local_evento"  class="result__container mb-1">Próximos eventos</h3>
              <p>
                <span class="contador-eventos mt-3 pt-3"
                  >{{ countEvents }} {{ textForCount }}</span
                >
              </p>
            </div>
            <!-- Agendamento -->
            <div
              v-if="siteName == 'live.bilheteria.com.br'"
              class="op2 col-sm-12 text-right"
            >
              <h3 class>Agendamento</h3>
              <p class="mt-3 mb-0 pb-0">
                Para incluir os espetáculos desejados em sua agenda
              </p>
              <br />
              <a
                style="text-align: center"
                href="https://calendar.google.com/calendar/r?cid=ZXZlbnRvc2JpbGhldGVyaWFjb21AZ21haWwuY29t"
                target="blank"
              >
                <img
                  style="align-items: center"
                  src="../../../../public/assets/googlecalendar.png"
                  alt
                />
              </a>
              <!-- Outlook Calendar -->
              <a
                href="https://blog.bilheteria.com/download/agenda/?wpdmdl=449&amp;masterkey=5ed55f5bcb78f"
                target="blank"
              >
                <img
                  class="outlook-calendar"
                  src="../../../../public/assets/outlookcalendar.png"
                  alt
                />
              </a>
              <!-- Fim Outlook Calendar -->
            </div>
            <!-- Fim Agendamento -->
          </div>
          <div class="row">
            <div class="col-sm-12 pb-1 text-left mt-4" v-if="['GAMES', 'GAM', 'GAME'].includes(searchValue.toUpperCase())">
              <h4 class="result__container mb-1"> Todos os jogos são gratuitos. Para Jogar, basta baixá-los, se cadastrar e seguir as instruções.
                Se necessário, é possivel adquirir acessórios e habilidades adicionais através da opção de compra conforme print.
              </h4>
            </div>
            <card-event
              v-for="(item, index) in searchResults"
              :key="index"
              :item="item"
            ></card-event>
          </div>
        </div>
      </section>
    </div>
    <RotateSquareLoader v-if="['Bilheteria.com.br', 'demo.bilheteria.com.br'].indexOf(siteName) > -1" :loading="loading" squareColor="#ff9000"></RotateSquareLoader>
  </div>
</template>

<script>
import Vue from "vue";
import VueHead from "vue-head";
import Logo from "@/components/App-logo.vue";
import config from "@/config";
import { func } from "@/functions";
import { searchService } from "@/components/common/services/search";
import SearchItemLoader from "@/components/loaders/SearchItemLoader.vue";
import AppSearch from "@/components/App-search.vue";
import CardEvent from "@/components/Card-event.vue";
import CardGenreSwiper from "@/components/Card-genre-swiper.vue";
import SearchByPrice from "@/components/Search-by-price.vue"

import CardRecommended from "@/components/Card-recommended.vue";
import RotateSquareLoader from "@/components/loaders/RotateSquareLoader";
import { eventService } from "@/components/common/services/event";
import { genresService } from "@/components/common/services/genres";
import { discoveryService } from "@/components/common/services/discovery";
Vue.use(VueHead);

export default {
  name: "search",
  components: {
    CardGenreSwiper,
    SearchItemLoader,
    Logo,
    AppSearch,
    CardEvent,
    CardRecommended,
    SearchByPrice,
    RotateSquareLoader
  },
  head: {
    title: function () {
      return {
        inner: `${this.readableRoute.name} / ${this.readableRoute.type}`,
        separator: " - ",
        complement: config.info.title,
      };
    },
    meta: function () {
      return [
        {
          name: "application-name",
          id: "application-name",
          content: this.metaObj.appName,
        },
        {
          name: "description",
          id: "description",
          content: this.metaObj.description,
        },
        {
          name: "keywords",
          id: "keywords",
          content: this.metaObj.keywords,
        },
        {
          itemprop: "name",
          id: "name",
          content: this.metaObj.name,
        },
        {
          itemprop: "description",
          id: "itempropdescription",
          content: this.metaObj.description,
        },
        {
          name: "twitter:card",
          id: "twitter_card",
          content: this.metaObj.twitter.card,
        },
        {
          name: "twitter:title",
          id: "twitter_title",
          content: this.metaObj.twitter.title,
        },
        {
          name: "twitter:description",
          id: "twitter_description",
          content: this.metaObj.twitter.description,
        },
        {
          name: "twitter:image",
          id: "twitter_image",
          content: this.metaObj.twitter.image.root,
        },
        {
          name: "twitter:image:alt",
          id: "twitter_imagealt",
          content: this.metaObj.twitter.image.alt,
        },
        {
          property: "og:title",
          id: "og_title",
          content: this.metaObj.og.title,
        },
        {
          property: "og:type",
          id: "og_type",
          content: this.metaObj.og.type,
        },
        {
          property: "og:url",
          id: "og_url",
          content: this.metaObj.og.url,
        },
        {
          property: "og:description",
          id: "og_description",
          content: this.metaObj.og.description,
        },
        {
          property: "og:site_name",
          id: "og_site_name",
          content: this.metaObj.og.site_name,
        },
        {
          property: "og:image",
          id: "og_image",
          content: this.metaObj.og.image.root,
        },
        {
          property: "og:image:secure_url",
          id: "og_image_secure_url",
          content: this.metaObj.og.image.secure_url,
        },
        {
          property: "og:image:type",
          id: "og_image_type",
          content: this.metaObj.og.image.type,
        },
        {
          property: "og:image:alt",
          id: "og_image_alt",
          content: this.metaObj.og.image.alt,
        },
      ];
    },
    link: function () {
      return [
        {
          rel: "icon",
          href: `${config.info.favicoPath}/favicon-32x32.png`,
          sizes: "32x32",
          type: "image/png",
        },
        {
          rel: "icon",
          href: `${config.info.favicoPath}/favicon-16x16.png`,
          sizes: "16x16",
          type: "image/png",
        },
      ];
    },
  },
  data() {
    return {
      metaObj: this.metatag_getObj(),
      filterCards: false,
      filterLists: true,
      loading: false,
      searchResults: [],
      discoveryBanner: [],
      getGenreList: [],
      discovery: [],
      searchValue: '',
      isLoaded: false,
      swiperOptionBanner: {
        //loop: true,
        autoplay: true,
        speed: 1000,
        //loopedSlides: 1,
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next-banner",
          prevEl: ".swiper-button-prev-banner",
        },
        breakpoints: {
          800: {
            slidesPerView: 1,
          },
        },
      },
      swiperOptionGenresWithoutImages: {
        autoplay: false,
        slidesPerView: 8,
        slidesPerGroup: 2,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next-genres-without-text",
          prevEl: ".swiper-button-prev-genres-without-text",
        },
        breakpoints: {
          800: {
            slidesPerView: 4,
          },
          600: {
            slidesPerView: 3,
          }
        }
      },
      itau: false,
      infoLocal: {
        imageURIBanner: "",
      },
      slideData: [],
      siteName: config.info.siteName,
    };
  },
  mixins: [func],
  computed: {
    categoriesList() {
      return this.$store.state.categoriesList
    },
    classBtn() {
      if (this.siteName == "ingressoparatodos.com.br") return "";
      return "dark";
    },
    stylesHeroSection() {
      if (this.infoLocal && this.infoLocal.imageURIBanner)
        return {
          "background-image": "url(" + this.infoLocal.imageURIBanner + ")",
        };
      return { "background-image": "url(/assets/background_local.jpg)" };
    },
    isMobile() {
      return window.innerWidth <= 760;
    },
    classes() {
      if (this.siteName == "ingressoparatodos.com.br") return "to-block2";
      if (this.siteName === 'games.bilheteria.com.br' && !this.isMobile) return 'custom-games-template'
    },
    textForCount() {
      return this.countEvents == 1
        ? "resultado encontrado"
        : "resultados encontrados";
    },
    countEvents() {
      return this.searchResults.length;
    },
    readableRoute() {
      let type = "";
      let name = this.searchValue;
      switch (this.$router.currentRoute.name) {
        case "search":
          type = "Busca";
          break;
        case "search_bycity":
        case "search_bycity_without_search":
          type = "Busca por Cidade";
          break;
        case "search_bystate":
          type = "Busca por Estado";
          break;
        case "search_bylocal":
        case "search_bylocal_without_search":
          type = "Busca por Local";
          break;
        case "search_bygenre":
          type = "Busca por Gênero";
          break;
        case "search_bycategory":
          type = "Busca por Categoria";
          break;
      }
      return {
        name,
        type,
      };
    },
    getGenreDesc() {
      const query = this.$route.query;
      const id_genre = query.id_genre ? query.id_genre : null;
      const genres = this.$store.state.genresList;
      if (genres && genres.length > 0) {
        const find = genres.find((item) => item.id == id_genre);
        return find ? find.name : "";
      }
      return "";
    },
    getCityDesc() {
      const query = this.$route.query;
      const id_municipio = query.id_municipio ? query.id_municipio : null;
      const cities = this.$store.state.cityList;
      if (cities && cities.length > 0) {
        const find = cities.find((item) => item.id_municipio == id_municipio);
        return find ? find.ds_municipio : "";
      }
      return "";
    },
    getDateWhenDesc() {
      const query = this.$route.query;
      const date_when = query.date_when ? query.date_when : null;
      if (date_when) {
        const date_array = date_when.split("-");
        const date = new Date(date_array[0], date_array[1] - 1, date_array[2]);
        const day = String(date.getDate()).padStart(2, "0");
        const day_week = date.toLocaleDateString("pt-br", { weekday: "long" });
        let month_name = date.toLocaleString("pt-br", { month: "long" });
        month_name = month_name.charAt(0).toUpperCase() + month_name.slice(1);
        return `${day} de ${month_name} (${day_week})`;
      }
      return "";
    },
    getAmountDesc() {
      const query = this.$route.query;
      const amount = query.amount ? query.amount : null;
      if (amount == "15") return "Até R$ 15,00";
      if (amount == "25") return "Até R$ 25,00";
      if (amount == "30") return "Até R$ 30,00";
      if (amount == "50") return "Até R$ 50,00";
      if (amount == "1000") return "Tanto Faz";
      return null;
    },
    getCategorysDesc() {
      const query = this.$route.query;
      const id_cat = query.id_cat ? query.id_cat : null;
      const categorys = this.$store.state.categoryList;
      if (categorys && categorys.length > 0) {
        const find = categorys.find((item) => item.id_cat == id_cat);
        return find ? find.cat_name : "";
      }
      return "";
    },
  },
  methods: {
    map(event) {
      if (event) event.preventDefault();

      window.open(
        "http://maps.google.com/?q=" +
          (this.infoLocal.ds_googlemaps == null
            ? this.infoLocal.ds_local_evento
            : this.infoLocal.ds_googlemaps)
      );
    },
    openSite (event) {
      if (event) event.preventDefault();
      let newUri = this.infoLocal.url
      if (!newUri.includes('http')) {
        newUri = 'https://' + this.infoLocal.url
      }
      window.open(newUri, '_blank')
    },
    getDiscovery() {
      discoveryService.list(this.key).then(
        (response) => {
          response.forEach(x => {
            if (x.type && x.type === 'card') {
              x.img = x.imageURI
              x.genreName = 'PROMOÇÕES'
              x.id_genre = '0002'
              x.datas = ''
              x.ds_evento = x.title

              if (this.searchValue === 'PROMOÇÕES') {
                this.searchResults.push(x)
              } else {
                this.slideData.push(x)
              }
            }
            this.discoveryBanner = response.filter((x) => x.type == "banner");
          });
          this.loading = false
        },
        (error) => {
          this.loading = false
          this.toastError("Falha na execução.");
        }
      );
    },
    getGenres() {
      genresService.get().then(
        (response) => {
          if (response !== null) {
           response.push({id: '0002', name: 'PROMOÇÕES'})
           this.getGenreList = response.sort((a, b) => {
              if (a.name > b.name) {
                return 1
              }
              if (a.name < b.name) {
                return -1
              }
              return 0;
            })
          }
        },
        (error) => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );
    },
    getListResultAgain() {
      eventService.list(this.locale.city.name, this.locale.state.name).then(
        (response) => {
          this.slideData = response.filter(
            (x) => x.id_genre !== undefined && x.id_genre !== null
          );
          this.hideWaitAboveAll();
        },
        (error) => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );
    },
    getListResults(callback) {
      this.getLocation(this.getListResultAgain);

      eventService.list(this.locale.city.name, this.locale.state.name).then(
        (response) => {
          this.slideData = response.filter(
            (x) => x.id_genre !== undefined && x.id_genre !== null
          );
          this.hideWaitAboveAll();
          this.isLoaded = true;

          if (callback !== null && callback !== undefined) {
            callback();
          }
        },
        (error) => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );
    },
    toggleFilter: function (type) {
      if (type === "lists") {
        this.filterCards = true;
        this.filterLists = false;
      } else {
        this.filterLists = true;
        this.filterCards = false;
      }
    },
    goto(item) {
      this.$router.push(item.uri);
    },
    getSearchResults(type, input, id_genre, id_municipio, date_when, amount) {
      this.loading = true
      this.searchResults = {}
      searchService
        .searchPage(
          type,
          input,
          null,
          null,
          null,
          null,
          id_genre,
          id_municipio,
          date_when,
          amount
        )
        .then(
          (response) => {
            this.searchResults = response;
            this.searchResults.map((x) => (x.img = x.cardimage));
            this.getDiscovery();
            //console.log(response);
            this.isLoaded = true;
            this.loading = false
          },
          (error) => {
            this.loading = false
            this.toastError("Falha na execução.");
          }
        );
    },
    createMetaObj() {
      this.metaObj.appName = config.info.siteName;
      this.metaObj.description = config.info.meta_description;
      this.metaObj.keywords = config.info.meta_keywords;
      this.metaObj.name = config.info.siteName;
      this.metaObj.twitter.card = "summary_large_image";
      this.metaObj.twitter.title = config.info.siteName;
      this.metaObj.twitter.description = config.info.meta_description;
      this.metaObj.twitter.image.root = config.info.logo;
      this.metaObj.twitter.image.alt = config.info.siteName;
      this.metaObj.og.title = config.info.siteName;
      this.metaObj.og.type = "website";
      this.metaObj.og.url = config.host;
      this.metaObj.og.description = config.info.meta_description;
      this.metaObj.og.site_name = config.info.siteName;
      this.metaObj.og.image.root = config.info.logo;
      this.metaObj.og.image.secure_url = config.info.logo;
      this.metaObj.og.image.type = "image/jpeg";
      this.metaObj.og.image.alt = config.info.siteName;
      this.$emit("updateHead");
    },
    getInfoLocal() {
      searchService.getInfoLocal(this.searchValue).then(
        (response) => {
          this.infoLocal = response;
        },
        (error) => {
          this.toastError("Falha na execução.");
        }
      );
    },
    renderAgain () {
      const query = this.$route.query;
      const id_genre = query.id_genre ? query.id_genre : null;
      const id_municipio = query.id_municipio ? query.id_municipio : null;
      const date_when = query.date_when ? query.date_when : null;
      const amount = query.amount ? query.amount : null;
      const id_cat = query.id_cat ? query.id_cat : null;
      this.createMetaObj();
      if (
        this.$router.currentRoute.name == "search_bylocal" ||
        this.$router.currentRoute.name == "search_bylocal_without_search"
      )
        this.getInfoLocal();
      this.getSearchResults(
        this.$router.currentRoute.name,
        this.searchValue,
        id_genre,
        id_municipio,
        date_when,
        amount,
        id_cat
      );
      this.getListResults();
      this.getGenres();
      window.scrollTo({ top: 450, behavior: 'smooth' });
    }
  },
  created() {
    this.searchValue = this.$route.params.input ? this.$route.params.input : ""
    this.renderAgain()
  },
  filters: {
    truncate: function (text, length, clamp) {
      clamp = clamp || "...";
      var node = document.createElement("div");
      node.innerHTML = text;
      var content = node.textContent;
      return content.length > length
        ? content.slice(0, length) + clamp
        : content;
    },
    replace: function (message, toReplace, replacement) {
      return message.replace(toReplace, replacement);
    },
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      value = value.toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-genre-search {
  margin-right: -15px;
  margin-left: -15px;
  @media only screen and (max-width: 760px) {
    margin: 0px;
  }
}
.hero-section {
  position: relative;
  height: 52vh;
  min-height: 350px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.content-section {
  position: relative;
  z-index: 1;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}

.org-id {
  z-index: 100;
  display: block;
  overflow: hidden;
  width: 200px;
  margin-top: -156px;
  // margin-right: auto;
  margin-left: 10%;
  padding: 4px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 23px 35px -16px;
}

.org-avatar-block {
  z-index: 1;
  overflow: hidden;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 8px;
  background-color: #fff;
}

.content-wrapper {
  position: relative;
  display: block;
  width: 100%;
  max-width: 1136px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-bottom: 2px;
  padding-left: 24px;
}

.content-block.list-head {
  padding-top: 16px;
  padding-bottom: 0px;
}
.content-block {
  padding: 16px 32px 24px;
  color: #000;
  text-align: center;
}

.event-name {
  margin-top: 0px;
  margin-bottom: 16px;
  font-size: 32px;
  line-height: 48px;
  font-weight: 800;
  letter-spacing: 0.2px;
}

@media screen and (max-width: 515px) {
  .container.menu {
    .categories-title-container {
      h3 {
        font-size: 17px;
      }
      p {
        font-size: 15px;
      }
    }
    .items-menu {
      flex-direction: column;
      height: 60px;
      overflow: auto;
      .shop_button_event {
        width: auto !important;
        height: 35px !important;
        font-size: 9px !important;
        font-weight: bold;
        margin: 5px !important;
        padding: 5px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.custom-games-template {
  background-image: url(/assets/game-background.png) !important;
  background-repeat: no-repeat !important;
  background-color: #000 !important;
  background-size: 'contain' !important;
}
</style>
