<template>
  <div class="container__ask_code2">
    <!-- Remove the commented-out div -->
    <section>
      <img class="cp-horizontal-desktop-background" src="/assets/images/novo_corporativo/horizontal_wave.svg" />
      <div class="main-corporative-container">
        <div class="cp-header-logo">
          <img class="bilheteria-logo" src="/assets/images/logocorporativobilheteria.png" alt="Bilheteria.com" />
        </div>
        <div class="cp-header-title">
          <h1 class="txts-askcode-bilheteria">BEM-VINDO AO PROGRAMA CORPORATIVO DE ENTRETENIMENTOS</h1>
        </div>
        <div class="cp-ask-code">
          <p class='cp-title'>Esqueceu o código de acesso ou quer saber se sua empresa está conveniada?</p>
          <div class="cp-input">
            <input type='text' v-model="form.pass" 
            data-rule="required" 
            v-mask="'##.###.###/####-##'" 
            placeholder="Informe o CNPJ da empresa" class="form-control"
              @keyup.enter="verifyAccess" />
            <div class='cp-buttons'>
              <div class='cp-button' style='background: #FF3C66;' @click="$router.push('AskCode')">
                <span>VOLTAR</span>
              </div>
              <div class='cp-button' @click="verifyAccess">
                <span>ENVIAR</span>
              </div>
            </div>
          </div>
        </div>
        <div class="cp-footer"></div>
      </div>
    </section>
    <LottieLoader :loading="processing" squareColor="#fdc80a"></LottieLoader>
  </div>
</template>

<script>
import Vue from "vue";
import VModal from "vue-js-modal";
import config from "@/config";
import { func } from "@/functions";
import { authService } from "@/components/common/services/auth";
import { corporateService } from "@/components/common/services/corporate";
import { partnerService } from "@/components/common/services/partner";
import LottieLoader from "@/components/loaders/LottieLoader";
import VueMask from "v-mask";
Vue.use(VueMask);
Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
});

export default {
  mixins: [func],
  name: "VerifyAccess",
  components: {LottieLoader},
  directives: {},
  computed: {},
  head: {
    title: function () {
      return {
        inner: "",
        separator: " ",
        complement: "Programa Corporativo Bilheteria.com",
      };
    },
  },
  mounted() {
    this.getIpAddress();
  },
  methods: {
    resetMessages() {
      this.showApplyMessage = false;
      this.showResetCodeMessage = false;
    },
    /** 
     * Get the user's IP address using the ipify API and set it to the form
     */
    async getIpAddress() {
      this.form.ip = await fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((data) => data.ip)
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    async getCnpjInfo() {
      if (!this.form.pass) {
        return this.$root.$emit('message-alert', { title: "Campos em branco" });
      }
      if (this.processing) return false;
      this.processing = true;
      this.$wait.start("inprocess");
      this.showWaitAboveAll();
      const res = await corporateService.getCnpjInfo(this.form.pass.replaceAll(".", "").replaceAll("/", "").replaceAll("-", ""));
      
      if (res && res.code === '2') {
        this.$root.$emit('message-alert', { title: "CNPJ não encontrado" });
        this.hideWaitAboveAll();
        this.$wait.end("inprocess");
        this.processing = false;
        return false;
      }
      
      this.hideWaitAboveAll();
      this.$wait.end("inprocess");
      this.processing = false;

      return { 
        cnpj: this.form.pass,
        email: res.email,
        message: res.message,
        fantasia: res.fantasia,
        nome: res.nome,
        cep: res.cep,
        logradouro: res.logradouro,
        numero: res.numero,
        bairro: res.bairro,
        municipio: res.municipio,
        uf: res.uf,
        code: res.code
      };
    },
    async verifyAccess() {
      if (!this.form.pass) {
        return this.$root.$emit('message-alert', { title: "Campo em branco." });
      }
      if (this.processing) return false;
      const params = await this.getCnpjInfo();
      if (!params || params.code === 2) {
        return this.$root.$emit('message-alert', { title: "CNPJ inválido." });
      }
      this.resetMessages();
      this.processing = true;
      this.$wait.start("inprocess");
      this.showWaitAboveAll();
      const res = await corporateService.verifyAccess(this.form);
      this.hideWaitAboveAll();
      this.$wait.end("inprocess");
      this.processing = false;
      if (res && res.exists) {
        this.$root.$emit('message-alert', { title: "Sua empresa está cadastrada. Agora você precisa informar o seu e-mail abaixo." });
        this.$router.push({ name: "requestCode", params: { corporate_id: res.corporate_id } });
      } else {
        this.$router.push({ name: "requestBws", params });
        return false;
      }
    },
  },
  data() {
    return {
      processing: false,
      loaded: false,
      showwatch: "login",
      showResetCodeMessage: false,
      validateinfo: {
        searchTerm: true,
      },
      form: {
        pass: "",
        ip: ""
      },
    };
  }
};
</script>

<style lang="scss" scoped>
.black-background {
  background-color: black !important;
}

.cp-horizontal-desktop-background {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 6;
}

.main-corporative-container {
  position: relative;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  font-family: IBM Plex Sans;

  .cp-title {
    font-weight: 800;
    font-size: 1.4rem;
    color: white;
  }

  .bilheteria-logo {
    width: 100%;
  }

  .cp-header-title {
    display: flex;
    justify-content: center;
  }

  .txts-askcode-bilheteria {
    font-size: 1.8rem;
    width: 80vw;
    font-weight: 800;
    color: #FF9701;
    text-align: center;
    height: 5vw;
  }

  .cp-ask-code {
    text-align: center;
    margin-top: 2rem;

    .cp-input {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 0 20px;
    }

    .cp-buttons {
      width: 100%;
      display: flex;
      gap: 22px;

      .cp-button {
        width: 60%;
        background: #EF892E;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 39px;
        border-radius: 12px;
        cursor: pointer;
        span {
          font-weight: bold;
          color: white;
        }
      }
    }

    .form-control {
      border: 2px solid #FF5946;
      border-radius: 0.4rem;
      width: 100%;
      height: 3rem;
      font-size: 16px;
    }

  }
}

@media screen and (max-width: 820px) {

  .cp-horizontal-desktop-background {
    display: none;
  }

  .main-corporative-container {

    .cp-ask-code {
      .cp-title {
        font-size: 1.1rem;
      }

      .cp-input {
        gap: 0.3rem;
      }

      .form-control {
        font-size: 1rem;
      }
    }

    .txts-askcode-bilheteria {
      height: 17vw;
      font-size: 1rem;
    }

    .cp-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 40%;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('/assets/images/novo_corporativo/wave_mobile.png');
    }
  }

}
</style>