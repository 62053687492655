<template>
  <div class="content-all-circos-cdb">
    <h2 class="title-all-circos-cdb">Todos os circos</h2>
    <p class="count-circos-cdb">Listando 5.265 circos</p>
    <div class="all-circos-cdb">
      <div class="item-circo-cdb" v-for="(item, idx) in allCircos" :key="idx">
        <p class="title-circo-cdb">{{ item.title }}</p>
        <p class="address-circo-cdb">{{ item.address }}</p>
        <div class="payment-circo-cdb">
          <button class="body-tickets">INGRESSOS FÍSICOS</button>
          <button class="online-tickets">INGRESSOS ONLINE</button>
          <button class="see-more-circo-cdb" @click="goEvent(idx)">
            <p class="title-see-more-circo-cdb">Saiba mais</p>
            <span class="material-symbols-outlined know-more-icon">arrow_forward</span>
          </button>
        </div>
      </div>
    </div>
    <div class="paginate-circo-cdb">
      <p class="page-circo-cdb">anterior | próximo</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchAllCircos',
  components: {},
  props: [],
  data () {
    return {
      allCircos: [
        { title: 'Circo de Moscou', address: 'São Paulo/SP' },
        { title: 'Circo de Moscou', address: 'São Paulo/SP' },
        { title: 'Circo de Moscou', address: 'São Paulo/SP' },
        { title: 'Circo de Moscou', address: 'São Paulo/SP' },
        { title: 'Circo de Moscou', address: 'São Paulo/SP' },
        { title: 'Circo de Moscou', address: 'São Paulo/SP' },
        { title: 'Circo de Moscou', address: 'São Paulo/SP' },
        { title: 'Circo de Moscou', address: 'São Paulo/SP' }
      ]
    }
  },
  computed: { },
  methods: {
    goEvent (e) {
      this.$router.push(`/eventoteste/${e}`)
    }
  }
}
</script>

<style lang="scss">
</style>