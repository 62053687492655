import { render, staticRenderFns } from "./EventRoomLoader.vue?vue&type=template&id=b8d455c8&"
import script from "./EventRoomLoader.vue?vue&type=script&lang=js&"
export * from "./EventRoomLoader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "EventRoomLoader.vue"
export default component.exports