<template>
    <div class="container-img">
        <img src="https://compra.tixs.me/images/manutencao.jpg" alt="">
    </div>
</template>

<script>
import Vue from "vue";
import { func } from "@/functions";

export default {
    mixins: [func],
    name: "MaintenancePage",
}
</script>

<style>
    .container-img {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
</style>