<template>
	<div :style="{'background-color': background}" :class="{'hidde-rotate-square-loading': !loading, 'show-rotate-square-loading': loading}"  class="loadingSquare">
     <div style="margin: 0 auto;">
        <rotate-square-2 style="margin: 0 auto;"></rotate-square-2>
        <p :style="{color: textColor}">{{text}}</p>
     </div>
     <theme-style>
        .spinner--rotate-square-2::after {
            background: {{squareColor}} !important;
        }
     </theme-style>
    </div>
</template>

<script>
import {RotateSquare2} from 'vue-loading-spinner'
import ThemeStyle from '../ThemeStyle.vue'

export default {
  name: "RotateSquareLoader",
  props: {
    loading: {
        default: true,
        type: Boolean
    },
    background: {
        default: '#0000009e',
        type: String
    },
    textColor: {
       default: 'white',
       type: String 
    },
    squareColor: {
       default: 'black',
       type: String 
    },
    text: {
        default: 'buscando, aguarde...',
        type: String
    }
  },
  components: {
    RotateSquare2,
    ThemeStyle,
    RotateSquare2
  }
}
</script>
<style>

.hidde-rotate-square-loading {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
}

.show-rotate-square-loading {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s, visibility 0.3s;
}
.loadingSquare {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 99999999999999999999999999999;
  top: 0;
  display: flex;
  align-items: center;
}
</style>