import { render, staticRenderFns } from "./Request-access.vue?vue&type=template&id=2e477363&scoped=true&"
import script from "./Request-access.vue?vue&type=script&lang=js&"
export * from "./Request-access.vue?vue&type=script&lang=js&"
import style0 from "./Request-access.vue?vue&type=style&index=0&id=2e477363&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e477363",
  null
  
)

component.options.__file = "Request-access.vue"
export default component.exports