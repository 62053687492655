<template>
  <div class="container__ask_code_valeingresso">
    <DonwloadApp />
    <div class="flex-container">
      <div class="tickets-left-side">
        <div class="valeingresso-container">
          <img src="/assets/images/valeingresso/logo_bilheteria.png" alt="logo" id="logo-corporativo-ask-code" />
          <p id="text-ask-code-bilheteria">
            Parabéns! Você ganhou nosso vale ingresso, digite o código de
            validação abaixo, para ter acesso ao nosso conteúdo:</p>
          <div class="input-container">
            <input type="text" v-model='form.pass' class="form-control" @keyup.enter="emitLogin"
              placeholder="insira aqui seu código de validação" />
            <div class="icon" @click="emitLogin">
              <img src="/assets/images/valeingresso/arrow-left.png" alt="logo" />
            </div>
          </div>
        </div>
        <div id="how-to-use-container">
          <div class="vale-ingresso-title">COMO USAR O SEU VALE INGRESSO?</div>
          <div>
            <div><b style="color: white;">1.</b> Depois de inserir o código de validação faça seu <b
                style="color: white;">CADASTRO;</b></div>
            <div><b style="color: white;">2. </b> Escolha o evento de sua <b style="color: white;">preferência</b>,
              juntamente com a quantidade de
              ingressos desejada, e prossiga para o pagamento;</div>
            <div><b style="color: white;"> 3. </b> Na etapa de pagamento selecione a opção <b
                style="color: white;">"VALE INGRESSO"</b>, e insira o <b style="color: white;">código</b>
              novamente encontrado no rodapé do seu cartão, seja ele físico ou digital.</div>
          </div>
        </div>
        <div id="ticket-container" class="floating-div">
          <div id="ticket-image">
            <img src="/assets/images/valeingresso/ingresso-b.png" alt="ingresso">
          </div>
          <img src="/assets/images/valeingresso/arrow.png" alt="arrow">
        </div>
      </div>
      <div class="tickets-right-side">
        <div class="services-container">
          <p id="services-title">SÃO
            MAIS DE 500 PRODUTOS
            PARA VOCÊ ESCOLHER</p>
          <div class="services-available">
            <div class="services">
              <div class="service" v-for='(service, idx) in services' :key="service.label">
                <img :src="'/assets/images/valeingresso/' + service.icon + '.png'" :alt="service.label"
                  :class="{ shakeAndDragTheShadowAnimation: randomIndex === idx, pinkEffect: changeShadowColor, orangeEffect: !changeShadowColor }" />
                <p>{{ service.label.toUpperCase()
                  }}
                </p>
              </div>
            </div>
            <div class='services-description'>
              <p>
                Sua próxima grande aventura começa aqui. Desfrute da liberdade de escolher a experiência
                perfeita que espera por você. A Bilheteria.com está aqui para transformar cada
                oportunidade em momentos verdadeiramente inesquecíveis. <b style="color: white;">Bom divertimento!</b>
              </p>
            </div>
          </div>
        </div>
        <div class="remember-container">
          <div class="rmb-texts">
            <div class="rmb-title">LEMBRE-SE</div>
            <div class='rmb-description'>*O vale não gera troco. Se o custo do evento for maior,
              você poderá pagar a diferença de forma simples e rápida.
              *Atente-se a validade do seu vale ingresso.</div>
          </div>
          <div class="apps-qr-code">
            <div class="qr-title">BAIXE O APP</div>
            <div class="qrs">
              <img src="/assets/images/valeingresso/qr_google_merged.png" alt="google qrcode" />
              <img src="/assets/images/valeingresso/qr_apple_merged.png" alt="apple qrcode" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-waves">
    </div>
  </div>
</template>

<script>
import { func } from "@/functions";
import DonwloadApp from "@/components/DownloadApp.vue";
export default {
  mixins: [func],
  name: "ValeIngresso",
  components: { DonwloadApp },
  methods: {
    emitLogin() {
      this.$emit('login-fired-vale-ingresso', this.form.pass)
    },
    updateRandomIndex() {
      // Generate a random index
      const randomIndex = Math.floor(Math.random() * this.services.length);
      // Update the randomIndex data property
      this.randomIndex = randomIndex;
      this.changeShadowColor = !this.changeShadowColor;
    }
  },
  data() {
    return {
      form: {
        pass: "",
      },
      changeShadowColor: false,
      randomIndex: null,
      services: [
        {
          label: 'circos',
          icon: 'circos'
        },
        {
          label: 'cinema',
          icon: 'cinema'
        },
        {
          label: 'museus',
          icon: 'museus'
        },
        {
          label: 'parques',
          icon: 'parques'
        },
        {
          label: 'futebol',
          icon: 'futebol'
        },
        {
          label: 'teatro',
          icon: 'teatro'
        },
        {
          label: 'games',
          icon: 'games'
        },
        {
          label: 'stand-up',
          icon: 'stand-up'
        },
        {
          label: 'shows',
          icon: 'shows'
        },
        {
          label: 'carnaval',
          icon: 'carnaval'
        },
        {
          label: 'ecoturismo',
          icon: 'ecoturismo'
        },
        {
          label: 'aventuras',
          icon: 'aventuras'
        },
        {
          label: 'excursoes',
          icon: 'excursoes'
        },
        {
          label: 'festas',
          icon: 'festas'
        },
        {
          label: 'eventos',
          icon: 'eventos'
        },
      ]
    };
  },
  mounted() {
    setInterval(this.updateRandomIndex, 1250); // this control the time of the effect of shadow and shake
  }
};
</script>

<style lang="scss">
$orange: #EF8530;
$pink: #E82D5B;
$text-grey: #cdcdcd;
$text-white: #fff;

.container__ask_code_valeingresso {
  background: rgb(11, 11, 11);
  background: linear-gradient(76deg, rgb(11, 11, 11) 0%, rgb(23, 23, 23) 100%);
}

.flex-container {
  padding: 1.5rem 2.4rem;
  display: flex;
  flex-flow: row;
  gap: 2rem;
  min-height: 95vh;
  justify-content: space-between;
}

#text-ask-code-bilheteria {
  font-weight: 500;
  font-size: 1.5rem;
  color: white;
  margin: 2rem 0px;
  // width: 90%;
}

#logo-corporativo-ask-code {
  // height: 80px;
  max-width: 100%;
}

#how-to-use-container {
  margin: 2rem 0px;
  margin-bottom: 0;

  .vale-ingresso-title {
    color: $pink;
    font-weight: 800;
    font-size: 2.2rem;
  }

  div:nth-child(2) {
    color: $text-grey;
    font-size: 1.4rem;
  }
}

.input-container {
  display: flex;
  align-items: center;

  & input {
    border-radius: 0.6rem !important;
    max-width: 80%;
    height: calc(2.25rem + 0.25rem);
    padding: 1.375rem .75rem;
    letter-spacing: 5px;
  }

  & .icon {
    background-color: $text-white;
    color: $pink;
    border-radius: 1rem;
    width: 45px;
    font-size: 1.8rem;
    display: flex;
    padding: 0.4rem;
    justify-content: center;
    margin-left: 0.2rem;

    & img {
      width: 100%;
    }
  }
}

.tickets-left-side {
  width: 45%;
}

.tickets-right-side {
  width: 53%;
}

#services-title {
  color: $orange;
  font-weight: 800;
  font-size: 2.5rem;
  text-align: center;
}

.services-available>.services {
  display: flex;
  flex-flow: wrap;
  // gap: 0rem 1rem;
  gap: 0 1rem;
  justify-content: center;

  & .service {
    align-self: center;
    text-align: center;
    width: 173px;

    // max-width: 6rem;
    img {
      width: 45%;
    }

    p {
      margin: 0px;
      font-weight: bold;
      color: $text-white;
      font-size: 1.3rem;
    }
  }
}

.services-description {
  padding: 1rem;
  margin: 1rem;

  p {
    font-size: 1.4rem;
    color: $text-grey;
    margin: 0;
  }
}

.remember-container {
  display: flex;
  padding: 1rem;
  margin: 1rem;
  justify-content: space-between;

  .rmb-texts {
    width: 60%;
    padding-right: 1rem;
  }

  .rmb-title {
    color: $pink;
    font-weight: 800;
    font-size: 2rem;
  }

  .rmb-description {
    font-size: 1.4rem;
    color: $text-grey;
  }
}

.apps-qr-code {
  width: 300px;

  .qr-title {
    color: $orange;
    font-weight: 800;
    font-size: 2rem;
    text-align: center;
  }

  .qrs {
    display: flex;
    width: 100%;
    height: 8rem;
    justify-content: center;
  }
}

#ticket-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 600px !important;
  margin: 2rem auto;
  height: auto;
  position: relative;
  bottom: -100px;

  :nth-child(2) {
    width: 35%;
    position: relative;
    right: 0.6rem;
    filter: drop-shadow(rgb(0, 0, 0) 5px 5px 5px);
    bottom: 1.8vw;
  }

  #ticket-image {
    box-shadow: rgba(0, 0, 0, 0.05) 2px 6px 0px 7px;
    transform: rotate(-12deg);
    width: 100%;
    background: $pink;
    border-radius: 1rem;

    img {
      width: 100%;
      border-radius: 1rem;
      transform: rotate(-12deg);
      filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
    }
  }
}

.footer-waves {
  background-size: cover;
  height: 90px;
  background-image: url('/assets/images/valeingresso/waves_merged.png');
}

@media screen and (min-width: 420px) and (max-width: 1250px) {
  #ticket-container {
    height: 300px;
  }
}

@media screen and (min-width: 620px) and (max-width: 1250px) {
  .input-container input {
    max-width: 45%;
  }
}

@media screen and (max-width: 620px) {
  .input-container input {
    max-width: 95%;
  }
}

@media screen and (max-width: 420px) {
  #ticket-container {
    height: 200px;
  }
}

@media screen and (max-width: 1220px) {
  .flex-container {
    flex-flow: column;
    gap: 0;
    height: auto;
  }

  .tickets-left-side {
    width: 100%;
  }

  .tickets-right-side {
    width: 100%;
  }

  #logo-corporativo-ask-code {
    height: auto;
    width: fit-content;
  }

  #text-as-code-bilheteria {
    font-size: 1.1rem;
  }

  #ticket-container {
    max-width: 470px !important;
    // height: 200px;
    position: inherit;
  }

  #how-to-use-container .vale-ingresso-title {
    font-size: 1.2rem;
  }

  .input-container input {
    // max-width: 95%;
    letter-spacing: 0.7px;
    font-size: 12px;
  }

  #how-to-use-container div:nth-child(2) {
    font-size: 1.1rem;
  }

  #services-title {
    font-size: 1.5rem;
  }

  .services-available>.services .service {
    width: 85px;
  }

  .services-available>.services .service p {
    font-size: 0.8rem;
  }

  .services-available>.services .service img {
    width: 70%;
  }

  .services-description {
    margin: 0;
  }

  .services-description p {
    font-size: 1rem;
  }

  .remember-container {
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0;
  }

  .remember-container .rmb-texts {
    width: 100%;
  }

  .remember-container .rmb-description {
    font-size: 1rem;
  }

  .apps-qr-code {
    .qrs {
      justify-content: start;
    }

    .qr-title {
      text-align: start;
    }
  }


  .footer-waves {
    height: 40px;
    background-image: url('/assets/images/valeingresso/wave.png');
  }
}

// animations
.floating-div {
  position: relative;
  --floating-distance: -20px;
  animation: floatAnimation 3s ease-in-out infinite;
  /* Adjust the animation duration and timing as needed */
}

@keyframes shadowAnimation {
  0% {
    filter: drop-shadow(1px 5px 5px var(--color-of-shadow-effect));
  }

  40% {
    filter: drop-shadow(11px 5px 5px var(--color-of-shadow-effect));
  }

  70% {
    filter: drop-shadow(-11px 5px 5px var(--color-of-shadow-effect));
  }

  100% {
    filter: drop-shadow(1px 5px 5px var(--color-of-shadow-effect));
  }
}

.orangeEffect {
  --color-of-shadow-effect: rgba(197, 118, 39, 0.95);
}

.pinkEffect {
  --color-of-shadow-effect: rgba(197, 39, 102, 0.87);
}

.shakeAndDragTheShadowAnimation {
  animation: shadowAnimation 1s ease-in-out, rotateAnimation 1s ease-in-out;
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  80% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
    /* Start position */
  }

  50% {
    transform: translateY(var(--floating-distance));
    /* Upward floating position */
  }

  100% {
    transform: translateY(0);
    /* Return to the start position */
  }
}
</style>
