<template>
	<div :style="{'background-color': background}" :class="{'hidde-rotate-square-loading': !loading, 'show-rotate-square-loading': loading}"  class="loadingSquare">
     <div style="margin: 0 auto;">
      <lottie-player class="lottie-loader" src="/assets/images/novo_corporativo/lottie_loading.json"
          background="transparent" speed="0.9" loop freeze autoplay>
      </lottie-player>
        <p :style="{color: textColor}">{{text}}</p>
     </div>
    </div>
</template>

<script>

export default {
  name: "LottieLoader",
  props: {
    loading: {
        default: true,
        type: Boolean
    },
    background: {
        default: 'rgba(0, 0, 0, 0.91)',
        type: String
    },
    textColor: {
       default: 'white',
       type: String 
    },
    text: {
        default: 'aguarde...',
        type: String
    }
  },
  mounted() {
    const lottie = document.createElement('script')
    lottie.setAttribute('src', 'https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs')
    document.head.appendChild(lottie)
  },
  components: {}
}
</script>
<style>

.hidde-rotate-square-loading {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
}

.show-rotate-square-loading {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s, visibility 0.3s;
}
.loadingSquare {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 99999999999999999999999999999;
  top: 0;
  display: flex;
  align-items: center;
}

.lottie-loader {
    height: 150px;
}
</style>