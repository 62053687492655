var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 col-xl-4 col-md-6 p-2 text-left"},[(
      _vm.siteName == 'ingressoparatodos.com.br' ||
      _vm.siteName == 'peixeurbano.ticketoffice.me' ||
      (_vm.siteName == 'www.ingreshow.com.br' &&
        _vm.item.minAmount !== null &&
        _vm.item.minAmount !== '')
    )?_c('div',{staticClass:"ad__badge-it"},[(
        _vm.siteName == 'ingressoparatodos.com.br' &&
        _vm.item.minAmount !== null &&
        _vm.item.minAmount !== ''
      )?_c('span',{staticStyle:{"margin-top":"6px"}},[(_vm.item.mmAmountIsPer == 0)?[_vm._v("\n        A partir de\n        "),_c('span',{staticStyle:{"font-weight":"bold !important","font-size":"10px !important"}},[_vm._v(_vm._s(_vm.item.minAmount))])]:_vm._e(),(_vm.item.mmAmountIsPer != 0)?[_vm._v("\n        Desconto de\n        "),_c('span',{staticStyle:{"font-weight":"bold !important","font-size":"10px !important"}},[_vm._v(_vm._s(_vm.item.minAmount)+"%")])]:_vm._e()],2):(_vm.item.minAmount !== null && _vm.item.minAmount !== '')?_c('span',{staticStyle:{"margin-top":"6px"}},[(_vm.item.mmAmountIsPer == 0)?[_vm._v("\n        A partir de\n        "),_c('span',{staticStyle:{"font-weight":"bold !important","font-size":"12px !important"}},[_vm._v(_vm._s(_vm.item.minAmount))])]:_vm._e(),(_vm.item.mmAmountIsPer != 0)?[_vm._v("\n        Desconto de\n        "),_c('span',{staticStyle:{"font-weight":"bold !important","font-size":"12px !important"}},[_vm._v(_vm._s(_vm.item.minAmount)+"%")])]:_vm._e()],2):_vm._e()]):_vm._e(),_c('div',{staticClass:"to-box p-0"},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":_vm.item.uri}},[_c('div',{staticClass:"img-fluid rounded-0",staticStyle:{"background-size":"cover"},style:({
          backgroundImage: 'url(\'' + _vm.item.img + '?' + _vm.getDayForCache + '\')',
        })}),_c('div',{staticClass:"fundo-card-recomendado content to-box p-2 pt-0 pb-1",staticStyle:{"position":"relative","border-top-left-radius":"0 !important","border-top-right-radius":"0 !important"}},[_c('h4',{staticClass:"event__title pb-1"},[_c('strong',[_vm._v(_vm._s(_vm._f("truncate")(_vm.item.ds_evento,35, " ...")))])]),(_vm.siteName != 'games.bilheteria.com.br')?_c('p',{staticClass:"p-0 m-0 event__item event__item-date"},[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.item.datas))]),(_vm.item.showPartnerInfo == 1)?_c('span',{staticClass:"ml-1 mr-2"},[_vm._v("-")]):_vm._e(),(_vm.item.showPartnerInfo == 1)?_c('span',{},[_c('i',{staticClass:"far fa-handshake",staticStyle:{"padding-right":"6px"}}),_vm._v("\n            "+_vm._s(_vm.item.partner)+"\n          ")]):_vm._e()]):_vm._e(),(_vm.siteName != 'games.bilheteria.com.br')?_c('p',{staticClass:"p-0 pt-1 m-0 h-200 event__item event__item-local"},[_c('span',{staticClass:"bold",staticStyle:{"text-transform":"capitalize !important"}},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.item.ds_nome_teatro))+" -\n            "+_vm._s(_vm._f("capitalize")(_vm.item.ds_municipio))+",")]),_vm._v("\n          "+_vm._s(_vm.item.sg_estado)+"\n        ")]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }